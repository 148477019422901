import React from 'react'
import { Theme, withStyles } from "@material-ui/core"

const styles = (theme: Theme) => ({
    root:{
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: 10,
        padding: 10,
        width: '60%',
        marginLeft: '20%',
        marginRight: '20%',
        [theme.breakpoints.down('sm')]:{
            width: '80%',
            marginLeft: '10%',
            marginRight: '10%'
        },
        [theme.breakpoints.down('xs')]:{
            width: 'auto',
            marginLeft: 0,
            marginRight: 0
        }
    }
  })  

export interface Props {
    classes: any
}

const OurBestAgentsInOnePlacePageRow = withStyles(styles)((props:Props) =>  {
    const { classes } = props
    return (
        <h1 className={classes.root} style={{ textAlign:'center' }}>
            מיטב הסוכנים האיכותיים והמנוסים ביותר במקום אחד
        </h1>
    )
})

export default OurBestAgentsInOnePlacePageRow
