import * as React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import "./Reviews.css"
import { IReview } from "../../api"
import Review from "./Review"
import { connect } from 'react-redux'
import { fetchReviews } from '../../actions'

export interface Props {
    className?:string,
    reviews: IReview[],
    fetchReviews: () => void
}

export interface State {
    isSmallerThan760: boolean,
    isSmallerThan1000:boolean,
}

class Reviews extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            isSmallerThan760: false,
            isSmallerThan1000: false
        }
    }

    componentDidMount(){
        this.props.fetchReviews();

        window.addEventListener("resize", this.resize.bind(this));
        window.addEventListener("orientationchange", this.resize.bind(this));
        
        this.resize();
    }

    resize() {
        this.setState({isSmallerThan760: window.innerWidth <= 760, isSmallerThan1000: window.innerWidth <= 1000});
    }

    createSliderSettings() {
        return  {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: this.state.isSmallerThan760 ? 1 : this.state.isSmallerThan1000 ? 2: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
        }
    }

    renderReviews() {
        let i:number = 0; 
        return this.props.reviews.map(review=><Review key={i++} review={review}/>);
    }

    render() 
    {
        return (
            <div className={(this.props.reviews.length > 0) ? 'Reviews loaded ' + this.props.className : 'Reviews '  + this.props.className}>
                <div className='content'>
                    <h1>לקוחות ממליצים</h1>
                    <Slider {...this.createSliderSettings()}>
                        {this.renderReviews()}
                    </Slider>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state:any) => {
    return ({
        reviews: state.reviews
    });
};

export default connect(mapStateToProps, { fetchReviews })(Reviews)