import axios from "axios";
import { InsuranceType } from "../helpers/insurance";

const api = axios.create();
api.defaults.baseURL = '/'
api.defaults.headers = {
    authorization: process.env.REACT_APP_API_KEY && 'Basic ' + Buffer.from(process.env.REACT_APP_API_KEY).toString('base64')
}

export interface IContact{
    name?: string,
    phoneNumber?: string,
    insuranceType?: InsuranceType,
    ageRange?: number,
    insurancePayment?: number,
    isSmoking?: boolean,
    hadSurgery?: boolean, 
    medicinesRequired?: boolean,
    isDisabled?: boolean,
    hasLimitations?: boolean,
    workDescription?: string,
}

export interface IReview{
    profilePhotoUrl: string,
    displayName: string,
    starRating: number,
    comment: string
}

export default {
    getReviews: async (): Promise<IReview[]> => {
        return (await api.get('api/v1/reviews')).data;
    },

    postContact: async (contact:IContact, token: string): Promise<boolean> => {
        let config = { headers: { 
                token:token,
                ...api.defaults.headers
            }}
        return (await api.post('api/v1/contact', contact, config)).data.success;
    }, 

    sendToken: async(phoneNumber: string): Promise<boolean> => {
        return (await api.post('api/v1/phone/verify/' + phoneNumber)).data.success;
    },

    sendTokenWithContact: async(phoneNumber: string, contact:IContact): Promise<boolean> => {
        return (await api.post('api/v1/phone/verify/' + phoneNumber, contact)).data.success;
    },

    verifyToken: async(token: string): Promise<boolean> => {
        return (await api.post('api/v1/tokens/approve/'+ token)).data.success;
    }
}