import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles.css';
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import {Provider} from 'react-redux'
import configureStore from './configureStore'
import { PersistGate } from 'redux-persist/integration/react'
import GTM from './helpers/gtm'

const store = configureStore()

GTM.initialize()

if (process.env.REACT_APP_ENV === 'development'){
    console.log(`App is running in development mode`)
}
else{
    console.log(`App is running in production mode`)
}

ReactDOM.render(
  //<React.StrictMode>
    <Provider store={store.store}>
      <PersistGate loading={null} persistor={store.persistor}>
        <App/>
      </PersistGate>
    </Provider>,
  //</React.StrictMode>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
