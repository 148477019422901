import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFont } from '@fortawesome/free-solid-svg-icons'
import { Fab, List, ListItem, Slider, useMediaQuery, useTheme } from '@material-ui/core'
import { connect } from 'react-redux'
import { setAccessibility } from '../../actions'
import { IReduxState } from '../../reducers'
import RefreshIcon from '@material-ui/icons/Refresh'

interface FontSizeProps{
    fontSize: number
    onChange: (fontSize: number) => void
}

const FontSize = (props: FontSizeProps) => {
    const theme = useTheme();
    const smallView = useMediaQuery(theme.breakpoints.down('sm')); 
    
    return (
        <div style={{ display: 'flex', direction:'rtl'}}>
            <h2 style={{ float:'right', marginLeft:10, marginTop:20, width:100, textAlign:'right' }}>
                גודל גופן
            </h2>
            <div style={{marginTop:28, flex:1, display:'flex'}}>
                <FontAwesomeIcon icon={faFont} size='lg' style={{float:'right', marginLeft:5}}/>
                <div style={{paddingLeft:5, paddingRight:5, flex:1, marginLeft:5}}>
                    <Slider value={props.fontSize}
                        marks
                        onChange={(e, value) => props.onChange(value as number)} 
                        step={0.05} min={1} max={smallView ? 1.1 : 1.2}/>
                </div>
                <FontAwesomeIcon icon={faFont} size='sm' style={{float:'left'}}/>
            </div>
        </div>)
}

interface ContrastProps{
    highContrast: boolean,
    onChange: (highContrast: boolean) => void
}

/*const HighContrast = (props: ContrastProps) =>{
    return (
        <div style={{ display: 'flex', direction:'rtl'}}>
            <h2 style={{ float:'right', marginLeft:10, marginTop:20, width:100, textAlign:'right' }}>
                ניגודיות
            </h2>
            <div style={{marginTop:20, flex:1, display:'flex'}}>
                <FontAwesomeIcon icon={faAdjust} size='lg' style={{float:'right', marginLeft:5, paddingTop:7}}/>
                <div style={{paddingLeft:5, paddingRight:5, marginLeft:5, textAlign:'center'}}>
                    <Switch size='medium' checked={props.highContrast} onChange={(e, checked) => props.onChange(checked)}/>
                </div>
                <FontAwesomeIcon icon={faSun} size='lg' style={{float:'right', paddingTop:7}}/>
            </div>
        </div>)
}*/

interface Props {
    style?: React.CSSProperties
    fontSize: number
    highContrast: boolean
    setAccessibility: (accassibility: { highContrast: boolean, fontSize: number }) => void
}

const Accessibility = (props: Props) =>{

    return(
    <div style={{...props.style, width:300, padding:15}}>
        <List>
            <ListItem divider>
                <h1 style={{textAlign:'center', width:'100%'}}>תפריט נגישות</h1>
            </ListItem>
            <ListItem divider>
                <FontSize fontSize={props.fontSize} onChange={(fontSize) => props.setAccessibility({ 
                    highContrast:props.highContrast, 
                    fontSize:fontSize }) }/>
            </ListItem>
            {/*<ListItem divider>
                <HighContrast highContrast={props.highContrast} onChange={(highContrast) => props.setAccessibility({ 
                    highContrast:highContrast, 
                    fontSize:props.fontSize }) }/>
                </ListItem>*/}
            <ListItem divider>
                <Fab variant="extended"
                    onClick={() => props.setAccessibility({ fontSize:1, highContrast:false })} 
                    style={{ width:'100%', color:'white', backgroundColor:'black', display:'flex'}}>
                    <RefreshIcon style={{float:'left'}} color='inherit'/>
                    <div style={{flex:1, textAlign:'center'}}>
                        <h2>אפס נגישות</h2>
                    </div>
                </Fab>
            </ListItem>
        </List>
    </div>)
}

const mapStateToProps = (state: IReduxState) => {
    return ({
        fontSize: state.accessibility.fontSize,
        highContrast: state.accessibility.highContrast
    })
}

export default connect(mapStateToProps, {setAccessibility})(Accessibility)