import * as React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { Theme, useTheme, withStyles } from "@material-ui/core"

const styles = (theme: Theme) => ({
    root:{
        alignText: 'center'
    },
})

export interface Props {
    classes: any
}

const WhatWillYouGetPageRow = withStyles(styles)((props:Props) =>  {
    const { classes } = props
    const theme = useTheme()
    return (
        <div className={classes.root} style={{ textAlign:'center' }}>
            <h1>המקום שיעניק לכם</h1>
            <h2><FontAwesomeIcon color={theme.palette.primary.main} icon={faCheckCircle} style={{marginLeft:10}}/>שקט נפשי</h2>
            <h2><FontAwesomeIcon color={theme.palette.primary.main} icon={faCheckCircle} style={{marginLeft:10}}/>מתן מענה ועזרה מיידית בזמן אמת</h2>
            <h2><FontAwesomeIcon color={theme.palette.primary.main} icon={faCheckCircle} style={{marginLeft:10}}/>חסכון משמעותי בכסף שאתם משלמים מדי חודש</h2>
        </div>
    )
})

export default WhatWillYouGetPageRow
