import * as React from 'react'
import { LossOfWorkFitnessInsuranceCard } from '../InsurancesCardsPageRow'
import Collapsible from '../../Common/Collapsible'
import { withStyles } from '@material-ui/core'
import styles, { Classes } from './styles'

export interface Props {
    classes: Classes
}

const LossOfWorkFitnessInsuranceFAQPageRow = (props: Props) => {
    const { classes } = props
    return (
        <div>
            <div className={classes.title}>
                <LossOfWorkFitnessInsuranceCard/>
            </div>
            <div className={classes.questions}>
                <Collapsible style={{ marginBottom:10 }} title='מה נותן לי ביטוח אובדן כושר עבודה?'>
                    <p>
                    ביטוח זה מאפשר לך את השקט הכלכלי, במידה ותיאלץ להפסיק לעבוד לתקופה מסוימת עקב תאונה או מחלה.<br/>
                    הביטוח מעניק תשלום חודשי במצבים המוגדרים, ומומלץ לרכוש אותו – אם באופן עצמאי ואם דרך מקום העבודה.
                    </p>
                </Collapsible>
                <Collapsible style={{ marginBottom:10 }} title='מה כולל ביטוח אובדן כושר עבודה?'>
                    <p>
                    הביטוח משתנה בהתאם לחברת הביטוח, בהתאם לצרכיו של המבוטח.<br/>
                    בין הרכיבים הנפוצים בביטוח זה תוכלו למצוא פיצוי חודשי קבוע, שחרור מהפקדות לקרן הפנסיה, ואלמנטים נוספים.
                    </p>
                </Collapsible>
                <Collapsible style={{ marginBottom:10 }} title='ממה מושפע מחיר הביטוח?'>
                    <p>
                    מחירה של פוליסת ביטוח אובדן כושר עבודה, ייקבע בהתאם לפרמטרים שונים כגון גיל המבוטח, אורח חיים, מחלות, סוג העיסוק, ועוד.<br/>
                    </p>
                </Collapsible>
                <Collapsible style={{ marginBottom:10 }} title='ביטוח לאומי לא מכסה אותי בכל מקרה?'>
                    <p>
                    הביטוח הלאומי מעניק קצבאות במצבים של אובדן כושר עבודה, אך מדובר בסכומים זעומים – שאינם מכסים אפילו הוצאות בסיסיות.<br/>
                    כיסוי ביטוחי משלים הוא הכרחי, לשמירה על היכולת הכלכלית במצבים בלתי צפויים.
                    </p>
                </Collapsible>
            </div>
        </div>
    )
}

export default withStyles(styles)(LossOfWorkFitnessInsuranceFAQPageRow)