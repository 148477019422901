import * as React from "react"
import phoenixLogo from '../../images/phoenix-logo.png'
import migdalLogo from '../../images/migdal-logo.png'
import menoraLogo from '../../images/menora-logo.png'
import harelLogo from '../../images/harel-logo.png'
import clalLogo from '../../images/clal-logo.png'
import ayalonLogo from '../../images/ayalon-logo.png'
import altshulerLogo from '../../images/altshuler-logo.png'
import { Theme, withStyles } from "@material-ui/core"

const styles = (theme:Theme) =>({
    root:{
    },
    logo:{
        width: 100,
        paddingRight: 20,
        paddingLeft: 20
    }
})
interface Props {
    classes: any
}

const InsuranceCompaniesLogosPageRow = (props: Props) =>  {
    const { classes } = props
    const Logos: Array<string> = [
        phoenixLogo, migdalLogo, menoraLogo, harelLogo, clalLogo, ayalonLogo, altshulerLogo
    ];
    return (
        <div className={classes.root} style={{ textAlign:'center' }}>
            {Logos.map((logo, index) => <img key={index} className={classes.logo} src={logo} alt='Missing Logo'/>)}
        </div>
    )
}

export default withStyles(styles)(InsuranceCompaniesLogosPageRow)