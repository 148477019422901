import React from 'react'
import postContactSuccessPNG from '../../../images/post-contact-success.png'
import postContactFailurePNG from '../../../images/post-contact-failure.png'
import { Theme, withStyles } from '@material-ui/core'
import GTM from '../../../helpers/gtm'
import { InsuranceType } from '../../../helpers/insurance'
import { IReduxState } from '../../../reducers'
import { connect } from 'react-redux'

const styles = (theme: Theme) =>({
    title:{
        marginTop:0,
        color: theme.palette.primary.main
    },
    subtitle:{
        color: theme.palette.primary.main
    }
})

interface Classes {
    title: string
    subtitle: string
}

export interface Props {
    classes: Classes
    insuranceType?: InsuranceType
    postResult?: boolean
    onClose: () => void
}

const PostFormResult = (props: Props) => {
    const { classes, postResult, insuranceType } = props

    GTM.triggerSubmitFormEvent('lead_form', postResult ? 'success' : 'failure', insuranceType)

    return (
        <div>
            {postResult ? 
            <div>
                <h1 className={classes.title}>מעולה!</h1>
                <h3 className={classes.subtitle}>קיבלנו את הפרטים שלך ונחזור אליך בהקדם</h3>
                <img src={postContactSuccessPNG} alt='הצלחה'></img>
            </div>:
            <div>
                <h1 className={classes.title}>כמה חבל</h1>
                <h3 className={classes.subtitle}>לצערינו ארעה שגיאה באת קליטת הנתונים</h3>
                <img src={postContactFailurePNG} alt='כישלון'></img>
            </div>}
        </div>
    )
}

const mapStateToProps = (state: IReduxState) => {
    return ({
        postResult: state.postContactFormState.result,
        insuranceType: state.contactForm.insuranceType
    });
};

export default connect(mapStateToProps, null)(withStyles(styles)(PostFormResult))
