import React from 'react'
import { Theme, withStyles } from '@material-ui/core'
import { InsuranceCompaniesLogosPageRow, InsurancesCardsPageRow, AboutUsPageRow, WhatWillYouGetPageRow, OurBestAgentsInOnePlacePageRow, HowItWorksPageRow } from '../PageRows'
import { ContactUsPageRow } from '../PageRows'
import { PageStack, PageRow, AltPageRow } from '../Common/PageStack'

const styles = (theme:Theme) =>({
    root:{
    }
})

interface Props{
    classes: any,
}

const LandingPage = withStyles(styles)((props: Props) => {
    const {classes} = props
    
    return (    
        <PageStack classes={classes}>
            <PageRow style={{paddingBottom:50, paddingTop:50}}>
                <ContactUsPageRow/>
            </PageRow>
            <AltPageRow>
                <AboutUsPageRow/>
            </AltPageRow>
            <AltPageRow style={{paddingBottom:30}}>
                <InsuranceCompaniesLogosPageRow/>
            </AltPageRow>
            <PageRow style={{paddingBottom:30, paddingTop:30}}>
                <HowItWorksPageRow/>
            </PageRow>
            <AltPageRow style={{paddingBottom:30, paddingTop:30}}>
                <InsurancesCardsPageRow/>
            </AltPageRow>
            <PageRow style={{paddingTop:30}}>
                <WhatWillYouGetPageRow/>
            </PageRow>
            <PageRow>
                <OurBestAgentsInOnePlacePageRow/>
            </PageRow>
        </PageStack>
    )
})

export default LandingPage
