import { Theme, withStyles } from "@material-ui/core"
import * as React from "react"

const styles = (theme:Theme) =>({
    root:{
    }
})

interface Props {
    classes: any
}

const AboutUsPageRow = (props:Props) =>  {
    const {classes} = props
    return (
        <div className={classes.root} style={{textAlign:'center'}}>
            <h1>מי אנחנו ?</h1>
            <h3>
                Smart Agent מציעה השוואה והוזלה של ביטוחים משתלמים של חברות הביטוח הגדולות בישראל
                תוך התאמה אישית לגיל, לסטטוס, לעיסוק ולצרכים האישיים שלכם.
            </h3>
        </div>
    )
}

export default withStyles(styles)(AboutUsPageRow)

