import React from 'react'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/HighlightOff'
import ContactForm from './ContactForm'
import { InsuranceType } from '../../helpers/insurance'

interface ContactFormDialogProps {
    open: boolean
    insurance?: InsuranceType
    onClose: () => void
}

const ContactFormDialog = (props: ContactFormDialogProps) =>{
      const { open, onClose } = props

      return (
        <Dialog
            fullScreen={true}
            fullWidth
            maxWidth={'sm'}
            open={open}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
          <DialogTitle style={{width:'auto', height:30, textAlign:'center', }} dir='rtl' id="scroll-dialog-title">
            <IconButton color='primary' style={{position:'absolute', right:5, top: 5}}
              aria-label="close" 
              onClick={onClose}>
              <CloseIcon fontSize='large'/>
            </IconButton>
            </DialogTitle>
            <DialogContent style={{width:'auto', padding:0}} dividers>
                <ContactForm insurance={props.insurance} onClose={props.onClose}/>
            </DialogContent>
          </Dialog>
      )
}

export default ContactFormDialog