import * as React from "react"
import { useState } from "react"
import insurance, { InsuranceType } from "../../helpers/insurance"
import useContactFormClasses from "./contactFormClasses"

interface QueryProps{
    insurance?: InsuranceType;
    onAnswer:(value: any) => void;
}

interface BooleanQueryProps extends QueryProps {
    onAnswer:(value: boolean) => void;
}

interface StringQueryProps extends QueryProps {
    onAnswer:(value: string) => void;
}

export enum AgeRanges{
    From20To30,
    From30To40,
    From40To50,
    From50To65,
    Above65
}

export enum InsurancePayment{
    Between0To100,
    Between100To300,
    Between300To500,
    Above500,
    DontKnow,
    DontHaveInsurance
}

interface HowOldAreYouQueryProps extends QueryProps {
    onAnswer:(value: AgeRanges) => void;
}

export const HowOldAreYouQuery = (props: HowOldAreYouQueryProps) => {
    const [ageRange,setAgeRange] = useState< undefined | AgeRanges>(undefined)
    const classes = useContactFormClasses()
    return (<div>
        <h2>בן כמה אתה?</h2>
        <ul className={classes.options.container}>
            <li className={(ageRange === undefined) || (ageRange !== AgeRanges.From20To30)? classes.toggle.firstOption : classes.toggle.firstOptionSelected } 
                onClick={()=> { setAgeRange(AgeRanges.From20To30); props.onAnswer(AgeRanges.From20To30); }}>20-30</li>
            <li className={(ageRange === undefined) || (ageRange !== AgeRanges.From30To40)? classes.toggle.option : classes.toggle.optionSelected }   
                onClick={()=> { setAgeRange(AgeRanges.From30To40); props.onAnswer(AgeRanges.From30To40); }}>30-40</li>
            <li className={(ageRange === undefined) || (ageRange !== AgeRanges.From40To50)? classes.toggle.option : classes.toggle.optionSelected } 
                onClick={()=> { setAgeRange(AgeRanges.From40To50); props.onAnswer(AgeRanges.From40To50); }}>40-50</li>
            <li className={(ageRange === undefined) || (ageRange !== AgeRanges.From50To65)? classes.toggle.option : classes.toggle.optionSelected } 
                onClick={()=> { setAgeRange(AgeRanges.From50To65); props.onAnswer(AgeRanges.From50To65); }}>50-65</li>
            <li className={(ageRange === undefined) || (ageRange !== AgeRanges.Above65)? classes.toggle.lastOption : classes.toggle.lastOptionSelected }   
                onClick={()=> { setAgeRange(AgeRanges.Above65); props.onAnswer(AgeRanges.Above65); }}>מעל 65</li>
        </ul>
    </div>);
}

export const AreYouSmokingQuery = (props: BooleanQueryProps) => {
    const [isSmoking,setIsSmoking] = useState<undefined | boolean>(undefined)
    const classes = useContactFormClasses()
    return (<div>
        <h2>האם אתה מעשן?</h2>
        <ul className={classes.options.container}>
            <li className={(!isSmoking) ? classes.toggle.firstOption : classes.toggle.firstOptionSelected } 
                onClick={()=> { setIsSmoking(true); props.onAnswer(true); }}>כן</li>
            <li className={((isSmoking === undefined) || (isSmoking)) ? classes.toggle.lastOption : classes.toggle.lastOptionSelected } 
                onClick={()=> { setIsSmoking(false); props.onAnswer(false); }}>לא</li>
        </ul>
    </div>);
}

export const MedicinesRquiredQuery = (props: BooleanQueryProps) => {
    const [isMedicinesRquired,setIsMedicinesRquired] = useState< undefined | boolean>(undefined)
    const classes = useContactFormClasses()
    return (<div>
        <h2>האם אתה נוטל תרופות באופן קבוע?</h2>
        <ul className={classes.options.container}>
            <li className={(!isMedicinesRquired) ? classes.toggle.firstOption : classes.toggle.firstOptionSelected } 
                onClick={()=> { setIsMedicinesRquired(true); props.onAnswer(true); }}>כן</li>
            <li className={((isMedicinesRquired === undefined) || (isMedicinesRquired)) ? classes.toggle.lastOption : classes.toggle.lastOptionSelected } 
                onClick={()=> { setIsMedicinesRquired(false); props.onAnswer(false); }}>לא</li>
        </ul>
    </div>);
}

export const HadSurgeryQuery = (props: BooleanQueryProps) => {
    const [hadSurgery,setHadSurgery] = useState< undefined | boolean>(undefined)
    const classes = useContactFormClasses()
    return (<div>
        <h2>האם עברת ניתוח ב 5 שנים האחרונות?</h2>
        <ul className={classes.options.container}>
            <li className={(!hadSurgery) ? classes.toggle.firstOption : classes.toggle.firstOptionSelected } 
                onClick={()=> { setHadSurgery(true); props.onAnswer(true); }}>כן</li>
            <li className={((hadSurgery === undefined) || (hadSurgery)) ? classes.toggle.lastOption : classes.toggle.lastOptionSelected } 
                onClick={()=> { setHadSurgery(false); props.onAnswer(false); }}>לא</li>
        </ul>
    </div>);
}

export const IsDisabledQuery = (props: BooleanQueryProps) => {
    const [isDisabled,setIsDisabled] = useState< undefined | boolean>(undefined)
    const classes = useContactFormClasses()
    return (<div>
        <h2>האם נקבע לך נכות כלשהי?</h2>
        <ul className={classes.options.container}>
            <li className={(!isDisabled) ? classes.toggle.firstOption : classes.toggle.firstOptionSelected } 
                onClick={()=> { setIsDisabled(true); props.onAnswer(true); }}>כן</li>
            <li className={((isDisabled === undefined) || (isDisabled)) ? classes.toggle.lastOption : classes.toggle.lastOptionSelected } 
                onClick={()=> { setIsDisabled(false); props.onAnswer(false); }}>לא</li>
        </ul>
    </div>);
}

export const HasLimitationsQuery = (props: BooleanQueryProps) => {
    const [hasLimitations,setHasLimitations] = useState< undefined | boolean>(undefined)
    const classes = useContactFormClasses()
    return (<div>
        <h2>האם יש לך מגבלה בביצוע אחת מהפעולות הבאות?</h2>
        <p>לאכול, לשתות, להתרחץ, להתגלח, להתלבש, להתפשט, לקום ו/או לשכב.</p>
        <ul className={classes.options.container}>
            <li className={(!hasLimitations) ? classes.toggle.firstOption : classes.toggle.firstOptionSelected } 
                onClick={()=> { setHasLimitations(true); props.onAnswer(true); }}>כן</li>
            <li className={((hasLimitations === undefined) || (hasLimitations)) ? classes.toggle.lastOption : classes.toggle.lastOptionSelected } 
                onClick={()=> { setHasLimitations(false); props.onAnswer(false); }}>לא</li>
        </ul>
    </div>);
}

export const DiseasesInTheFamilyBackgroundQuery = (props: BooleanQueryProps) => {
    const [hasDiseasesInTheFamily,setHasDiseasesInTheFamily] = useState< undefined | boolean>(undefined)
    const classes = useContactFormClasses()
    return (<div>
        <h2>האם אחד מבני משפחתך מקרבה ראשונה, חס וחלילה, חלה בסרטן ו/או סכרת ו/או מחלת לב?</h2>
        <ul className={classes.options.container}>
            <li className={(!hasDiseasesInTheFamily) ? classes.toggle.firstOption : classes.toggle.firstOptionSelected } 
                onClick={()=> { setHasDiseasesInTheFamily(true); props.onAnswer(true); }}>כן</li>
            <li className={((hasDiseasesInTheFamily === undefined) || (hasDiseasesInTheFamily)) ? classes.toggle.lastOption : classes.toggle.lastOptionSelected } 
                onClick={()=> { setHasDiseasesInTheFamily(false); props.onAnswer(false); }}>לא</li>
        </ul>
    </div>);
}

export const WorkDescriptionQuery = (props: StringQueryProps) => {
    const classes = useContactFormClasses()
    return (<div>
        <h2>במה אתם עובדים?</h2>
        <input className={classes.input.regular} type='text' placeholder='סוג עבודה' onChange={(e)=>{ props.onAnswer(e.target.value); }}/>
    </div>);
}

interface InsurancePaymentQueryProps extends QueryProps {
    onAnswer:(value: InsurancePayment) => void;
}

export const InsurancePaymentQuery = (props: InsurancePaymentQueryProps)=>{
    let insuranceTitle ='ביטוח'
    if (props.insurance){
        insuranceTitle = insurance.getInsuranceTitle(props.insurance)
    }

    const [insurancePayment,setInsurancePayment] = useState< undefined | InsurancePayment>(undefined)
    const classes = useContactFormClasses()
    return (<div>
        <h2>האם ידוע לכם כמה אתם משלמים בחודש עבור {insuranceTitle}?</h2>
        <div>
            <ul style={{justifyContent:'center'}}>
                <li className={(insurancePayment === InsurancePayment.Between0To100) ? classes.options.optionSelected : classes.options.option} 
                    onClick={() => {setInsurancePayment(InsurancePayment.Between0To100); props.onAnswer(InsurancePayment.Between0To100)}}>בין 0 ל 100 ש"ח</li><br/>
                <li className={(insurancePayment === InsurancePayment.Between100To300) ? classes.options.optionSelected : classes.options.option} 
                    onClick={() => {setInsurancePayment(InsurancePayment.Between100To300); props.onAnswer(InsurancePayment.Between100To300)}}>בין 100 ל 300 ש"ח</li><br/>
                <li className={(insurancePayment === InsurancePayment.Between300To500) ? classes.options.optionSelected : classes.options.option} 
                    onClick={() => {setInsurancePayment(InsurancePayment.Between300To500); props.onAnswer(InsurancePayment.Between300To500)}}>בין 300 ל 500 ש"ח</li><br/>
                <li className={(insurancePayment === InsurancePayment.Above500) ? classes.options.optionSelected : classes.options.option} 
                    onClick={() => {setInsurancePayment(InsurancePayment.Above500); props.onAnswer(InsurancePayment.Above500)}}>מעל 500</li><br/>
                <li className={(insurancePayment === InsurancePayment.DontKnow) ? classes.options.optionSelected : classes.options.option} 
                    onClick={() => {setInsurancePayment(InsurancePayment.DontKnow); props.onAnswer(InsurancePayment.DontKnow)}}>לא יודע/ת</li><br/>
                <li className={(insurancePayment === InsurancePayment.DontHaveInsurance) ? classes.options.optionSelected : classes.options.option} 
                    onClick={() => {setInsurancePayment(InsurancePayment.DontHaveInsurance); props.onAnswer(InsurancePayment.DontHaveInsurance)}}>אין לי {insuranceTitle}</li>
            </ul>
        </div>
    </div>);
}