import { Theme, withStyles } from '@material-ui/core'
import React from 'react'
import { Form } from '../Common'
import postContactSuccessPNG from '../../images/post-contact-success.png'
import postContactFailurePNG from '../../images/post-contact-failure.png'
import GTM from '../../helpers/gtm'

const styles = (theme: Theme) =>({
    title:{
        marginTop:0,
        color: theme.palette.primary.main
    },
    subtitle:{
        color: theme.palette.primary.main
    }
})

interface Classes {
    title: string
    subtitle: string
}

interface Props{
    classes: Classes
    postResult?: boolean
}

const PostContactFormResult = (props: Props) =>{
    const { classes, postResult } = props
    
    GTM.triggerSubmitFormEvent('fast_lead_form', postResult ? 'success' : 'failure')
    
    return (
        <Form>
            {postResult ? 
            <div>
                <h1 className={classes.title}>מעולה!</h1>
                <h3 className={classes.subtitle}>קיבלנו את הפרטים שלך ונחזור אליך בהקדם</h3>
                <img src={postContactSuccessPNG} alt='הצלחה'></img>
            </div>:
            <div>
                <h1 className={classes.title}>כמה חבל</h1>
                <h3 className={classes.subtitle}>לצערינו ארעה שגיאה באת קליטת הנתונים</h3>
                <img src={postContactFailurePNG} alt='כישלון'></img>
            </div>}
        </Form>
    )
}

export default withStyles(styles)(PostContactFormResult)