import React from 'react'
import { Theme, withStyles } from '@material-ui/core'
import AboutUsImage from '../../images/about-us-image.png'
import WhyUsImage from '../../images/why-us-image.png'
import {PageStack,PageRow,AltPageRow} from '../Common/PageStack'
import { ContactUsPageRow } from '../PageRows'

const styles = (theme: Theme) => ({
    container:{
        width:'50%',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]:{
            width:'100%'
        }
    },
    image:{
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: 300,
        width: '90%',
        paddingRight: '5%',
        paddingLeft: '5%',
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            padding:0
        }
    },
    aboutUsImage:{
        backgroundImage: `url(${AboutUsImage})`,
    },
    whyUsImage:{
        backgroundImage: `url(${WhyUsImage})`,
    },
    text:{
        width: '90%',
        paddingRight: '5%',
        paddingLeft: '5%',
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            padding:0
        }
    },
})

interface Props{
    classes: any
}

const About = (props: Props) => {
    const { classes } = props
    
    return (
        <PageStack>
            <PageRow style={{paddingBottom:30, paddingTop:30}}>
                <div className={classes.container}>
                    <div className={classes.text} style={{textAlign:'center'}}>
                        <h1>קצת עלינו</h1>
                        <p>
                        Smart Agent משווים ומוזילים ביטוחים של חברות הביטוח הגדולות בישראל: מגדל, הראל, איילון, מנורה, פניקס ועוד.
                        </p>
                        <p>
                        אנו מאגדים עשרות סוכני ביטוח וותיקים ומנוסים ובעלי כל הכלים לבדוק שהביטוחים שלכם  מתאימים לכם ולמשפחתכם.
                        </p>
                    </div>
                </div>
                <div className={classes.container}>
                    <div className={classes.image + ' ' + classes.aboutUsImage}/>
                </div>
            </PageRow>
            <AltPageRow style={{paddingTop:30, paddingBottom:30}}>
                <div className={classes.container} style={{float:'left'}}>
                    <div className={classes.text} style={{textAlign:'center'}}>
                        <h1>למה אנחנו</h1>
                        <p>
                        נבצע עבורכם בדיקה והשוואה בין הביטוחים הקיימים שלכם בהתאם לנתונים שתזינו באתר.
                        מה כוללת הבדיקה? הוזלת דמי ניהול, מניעת כפל ביטוחי, הוזלת פרמיות ואף בדיקת אפשרות לשדרוג הפוליסות הקיימות.
                        </p>
                        <p>
                        השימוש בטכנולוגיות מתקדמות בשילוב צוות מומחים מיומן, מאפשרים לנו לתת לכם מענה מקצועי ומהיר בתחום הביטוח.
                        </p>
                    </div>
                </div>
                <div className={classes.container}>
                    <div className={classes.image + ' ' + classes.whyUsImage}/>
                </div>
            </AltPageRow>
            <PageRow style={{paddingTop:60}}>
                <ContactUsPageRow/>
            </PageRow>
        </PageStack>
    )
}

export default withStyles(styles)(About)