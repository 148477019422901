import * as React from "react";
import "./Review.css"
import { useState } from "react";
import { IReview } from "../../../api";

export interface ReviewProps {
    review: IReview
}

interface StarProps{
    fill:Boolean
}

const StarElement = (props: StarProps) => {
    let color = '#e7711b';
    let emptyColor = 'lightgray';
    return <svg viewBox='0 0 1792 1792' width='17' height='17' fill={props.fill ? color: emptyColor}> 
        <path d='M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z'/>
    </svg>
}

interface StarRatingProps{
    rating: number
}

const StarRatingElement = (props: StarRatingProps) =>{
    return <div className='star-rating'>
        <StarElement fill={(props.rating > 0)}/>
        <StarElement fill={(props.rating > 1)}/>
        <StarElement fill={(props.rating > 2)}/>
        <StarElement fill={(props.rating > 3)}/>
        <StarElement fill={(props.rating > 4)}/>
    </div> 
}

export default (props: ReviewProps) => {
    const [isImageLoaded, setIsImageLoaded] = useState(true);
    const {displayName, profilePhotoUrl, comment, starRating } = props.review;
    return (
        <div className='Review'>
            <div className='avatar'>
                {isImageLoaded ? 
                <img src={profilePhotoUrl} alt='Avatar' onError={()=>{setIsImageLoaded(false)}}></img>:
                <div>{displayName[0]}</div>}
            </div>
            <div className='details'>
                <div className='title'>{displayName}</div>
                <StarRatingElement rating={starRating}/>
                <div className='comment'>
                    {comment}
                </div>
            </div>
        </div>
    );
}
