import React, { useState } from "react"
import { connect } from "react-redux"
import { IContact } from "../../../api"
import { updateContactForm } from "../../../actions"
import { AgeRanges, HowOldAreYouQuery, HasLimitationsQuery, IsDisabledQuery, InsurancePayment, InsurancePaymentQuery } from '../FormQueries'
import useContactFormClasses from "../contactFormClasses"

export interface Props {
    onSubmit: (state: PersonalAccidentsInsuranceResult)=>void
    updateContactForm: (contact: IContact) => void
}

export interface PersonalAccidentsInsuranceResult {
    isDisabled?: boolean,
    hasLimitations?: boolean,
    ageRange?: AgeRanges,
    insurancePayment?: InsurancePayment
}

const PersonalAccidentsInsuranceForm = (props: Props) => {

    const [paymentQuery, setPaymentQuery] = useState(false);
    const [ageRange, setAgeRange] = useState(undefined);
    const [isDisabled, setIsDisabled] = useState(undefined);
    const [hasLimitations, setHasLimitations] = useState(undefined);
    const [insurancePayment, setInsurancePayment] = useState(undefined);

    const finished: boolean = ((ageRange !== undefined) && (isDisabled !== undefined) && (hasLimitations !== undefined));

    const onSubmit = () => {
        props.updateContactForm({ageRange, hasLimitations, isDisabled, insurancePayment } as IContact);
        props.onSubmit({ageRange, hasLimitations, isDisabled, insurancePayment})
    }

    const classes = useContactFormClasses()
    return (
        <div>
            <h1>ביטוח תאונות אישיות</h1>
            {(!paymentQuery) ?
            <div>
                <HowOldAreYouQuery onAnswer={(result: any) => {setAgeRange(result)}}/>
                <IsDisabledQuery onAnswer={(result: any) => {setIsDisabled(result)}}/>
                <HasLimitationsQuery onAnswer={(result: any) => {setHasLimitations(result)}}/>
                {(finished) ?
                    <button className={classes.buttons.regular} onClick={() => setPaymentQuery(true)}>המשך</button>:
                    <button className={classes.buttons.disabled}>המשך</button>}
            </div>:
            <div>
                <InsurancePaymentQuery insurance={'personal_accidents_insurance'}
                    onAnswer={ (sellection: any) => { setInsurancePayment(sellection) }}/>
                {(insurancePayment !== undefined) ?
                    <button className={classes.buttons.regular} onClick={onSubmit}>המשך</button>:
                    <button className={classes.buttons.disabled}>המשך</button>}
            </div>
            }
        </div>
    )
}

export default connect(undefined, { updateContactForm })(PersonalAccidentsInsuranceForm)