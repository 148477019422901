import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root:{
        color:theme.palette.text.primary
    }
}))

export interface AccessibilityStatementProps{

}

const AccessibilityStatement = (props: AccessibilityStatementProps) => {
    const css = useStyles()
    return(
        <div className={css.root} id="scroll-dialog-description" dir='rtl'>
            <div style={{paddingBottom:15}}>חברתנו פועלת ומקדמת את נושא הנגישות. אנו רואים באוכלוסייה עם מוגבלות אוכלוסייה שוות זכויות, הזכאית ליהנות מנגישות מלאה. החברה פועלת לפי המוגדר בחוק ואף למעלה מכך במטרה לסייע לאנשים עם מוגבלויות לשפר את איכות החיים ולאפשר את שילובם בחברה בדרך של כבוד, שוויון ועצמאות. התאמות אלו נעשות בכפוף ובהתאם להוראות חוק שוויון זכויות לאנשים עם מוגבלות.</div>
            <h3>הנגשת אתר האינטרנט</h3>
            <div style={{paddingBottom:15}}>אנו רואים חשיבות עליונה בהנגשת אתר האינטרנט לאנשים עם מוגבלויות, ובכך לאפשר למרבית האוכלוסייה לגלוש בקלות ובנוחות באתר המכללה.אתר האינטרנט של המכללה מונגש בהתאם לתקנות הנגישות אשר בחוק שוויון זכויות לאנשים עם מוגבלויות. המכללה משקיעה מאמצים ומשאבים רבים בשיפור הנגישות באתריההאתר נבנה בהתאם להוראות הנגישות המופיעות ב-W3C’s Web Content Accessibility Guidelines 2.0, Level AA</div>
            <div style={{paddingBottom:15}}>- האתר מותאם לתצוגה בדפדפנים הנפוצים ולשימוש בטלפון נייד חכם</div>
            <div style={{paddingBottom:15}}>- הצבעים באתר הותאמו על פי דרישות הניגודיות</div>
            <div style={{paddingBottom:15}}>- ניווט באתר בעזרת מקלדת בלבד: באמצעות מקש TAB, חיצים ו-Enter לבחירה בפריט</div>
            <div style={{paddingBottom:15}}>- אמצעי הניווט וההתמצאות באתר פשוטים ונוחים לשימוש</div>
            <div style={{paddingBottom:15}}>- תכני האתר כתובים בשפה פשוטה וברורה ומאורגנים היטב באמצעות כותרות ורשימות</div>
            <div style={{paddingBottom:15}}>- התאמת האתר לסביבות עבודה ברזולוציות שונות</div>
            <div style={{paddingBottom:15}}>- האתר מאפשר שינוי גודל הגופן בתפריט נגישות</div>
            <div style={{paddingBottom:15}}>- לשם קבלת חווית גלישה מיטבית עם תוכנת קורא מסך (Screen Reader), אנו ממליצים לשימוש בתוכנת NVDA העדכנית ביותר. </div>
            <h3>אפשרויות רכיב נגישות</h3>
            <div style={{paddingBottom:15}}>- שינוי ניגודיות צבעים על בסיס רקע כהה ו/או בהיר.</div>
            <div style={{paddingBottom:15}}>- התאמת האתר לעיוורי צבעים.</div>
            <div style={{paddingBottom:15}}>- הגדלת הפונט למספר גדלים שונים</div>
            <div style={{paddingBottom:15}}>- הדגשת קישורים</div>
            <div style={{paddingBottom:15}}>- הדגשת כותרות</div>
            <div style={{paddingBottom:15}}>- ניגודיות טקסט בשלוש רמות: עדינה, רגילה וגבוהה</div>
            <div style={{paddingBottom:15}}>- תצוגה של האתר בגווני אפור לעיוורי צבעים</div>
            <div style={{paddingBottom:15}}>- הצגת גופן קריא</div>
            <div style={{paddingBottom:15}}>- אפשרות לחסימת אנימציות</div>
            <div style={{paddingBottom:15}}>- הצהרת נגישות</div>
        </div>
    )
}

export default AccessibilityStatement