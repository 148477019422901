export type InsuranceType = 'health_insurance' | 'life_insurance' | 'personal_accidents_insurance' | 
    'loss_of_work_fitness_insurance' | 'severe_illness_insurance'

export type InsuranceName = 'ביטוח בריאות' | 'ביטוח חיים' | 'ביטוח תאונות אישיות' | 
    'ביטוח אובדן כושר עבודה' | 'ביטוח מחלות קשות'

const getInsuranceTitle = (insuranceType: InsuranceType): InsuranceName =>{
    switch (insuranceType) {
        case 'health_insurance':
            return 'ביטוח בריאות'
        case 'life_insurance':
            return 'ביטוח חיים'
        case 'loss_of_work_fitness_insurance':
            return 'ביטוח אובדן כושר עבודה'
        case 'personal_accidents_insurance':
            return 'ביטוח תאונות אישיות'
        case 'severe_illness_insurance':
            return 'ביטוח מחלות קשות'
    }
}

export default {
    getInsuranceTitle
}