import React from 'react'
import { PageStack, PageRow, AltPageRow } from '../Common/PageStack'
import { Link, Theme, withStyles } from '@material-ui/core'
import { ContactUsPageRow } from '../PageRows'
import { withInsurance } from '../HCO/withInsurance'
import { PersonalAccidentsInsuranceFAQPageRow, HealthInsuranceFAQPageRow, 
    SevereIllnessInsuranceFAQPageRow, LifeInsuranceFAQPageRow,
    LossOfWorkFitnessInsuranceFAQPageRow } from '../PageRows'
import { InsuranceType } from '../../helpers/insurance'

const FAQHeaderLink = withStyles((theme: Theme) =>({
    root:{
        cursor: 'pointer'
    }
}))(Link)

FAQHeaderLink.defaultProps = {
    variant:'h2',
    color:'primary',
    underline:'always'
}

interface Props{
    classes: any
    insurance: InsuranceType
}

const FAQPage = (props: Props) => {
    const { insurance } = props

    const lifeInsuranceFAQREF: React.Ref<HTMLDivElement> = React.createRef();
    const personalAccidentsInsuranceFAQREF: React.Ref<HTMLDivElement> = React.createRef();
    const severeIllnessInsuranceFAQREF: React.Ref<HTMLDivElement> = React.createRef();
    const healthInsuranceFAQREF: React.Ref<HTMLDivElement> = React.createRef();
    const lossOfWorkFitnessInsuranceFAQREF: React.Ref<HTMLDivElement> = React.createRef();

    const scrollRefIntoView = (ref: any) => {
        ref.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <PageStack>
            { (insurance === undefined) && 
                <React.Fragment>
                    <PageRow style={{paddingBottom:30, paddingTop:30}}>
                        <div style={{textAlign:'center'}}>
                            <div>
                                <div style={{marginLeft:8, marginRight:8}}>
                                    <h1>שאלות נפוצות</h1>
                                </div>
                                <div style={{margin:8}}>
                                    <FAQHeaderLink onClick={()=>{scrollRefIntoView(healthInsuranceFAQREF.current)}}>ביטוח בריאות</FAQHeaderLink>
                                </div>
                                <div style={{margin:8}}>
                                    <FAQHeaderLink onClick={()=>{scrollRefIntoView(lifeInsuranceFAQREF.current)}}>ביטוח חיים</FAQHeaderLink>
                                </div>
                                <div style={{margin:8}}>
                                    <FAQHeaderLink onClick={()=>{scrollRefIntoView(personalAccidentsInsuranceFAQREF.current)}}>ביטוח תאונות אשיות</FAQHeaderLink>
                                </div>
                                <div style={{margin:8}}>
                                    <FAQHeaderLink onClick={()=>{scrollRefIntoView(lossOfWorkFitnessInsuranceFAQREF.current)}}>ביטוח אובדן כושר עבודה</FAQHeaderLink>
                                </div>
                                <div style={{margin:8}}>
                                    <FAQHeaderLink onClick={()=>{scrollRefIntoView(severeIllnessInsuranceFAQREF.current)}}>ביטוח מחלות קשות</FAQHeaderLink>
                                </div>
                            </div>
                        </div>
                    </PageRow>
                    <div ref={healthInsuranceFAQREF}/>
                    <AltPageRow style={{paddingBottom:30, paddingTop:30}}>
                        <HealthInsuranceFAQPageRow/>
                    </AltPageRow>
                    <div ref={lifeInsuranceFAQREF}/>
                    <PageRow style={{paddingBottom:30, paddingTop:30}}>
                        <LifeInsuranceFAQPageRow/>
                    </PageRow>
                    <div ref={personalAccidentsInsuranceFAQREF}/>
                    <AltPageRow style={{paddingBottom:30, paddingTop:30}}>
                        <PersonalAccidentsInsuranceFAQPageRow/>
                    </AltPageRow>
                    <div ref={lossOfWorkFitnessInsuranceFAQREF}/>
                    <PageRow style={{paddingBottom:30, paddingTop:30}}>
                        <LossOfWorkFitnessInsuranceFAQPageRow/>
                    </PageRow>
                    <div ref={severeIllnessInsuranceFAQREF}/>
                    <AltPageRow style={{paddingBottom:30, paddingTop:30}}>
                        <SevereIllnessInsuranceFAQPageRow/>
                    </AltPageRow>
                    <PageRow style={{paddingTop:60, paddingBottom:30}}>
                        <ContactUsPageRow/>
                    </PageRow>
                </React.Fragment>}
            { (insurance !== undefined) && 
                <React.Fragment>
                    <PageRow>
                        <h1 style={{textAlign:'center'}}>שאלות נפוצות</h1>
                    </PageRow>
                    <PageRow style={{paddingBottom:30}}>
                        {(insurance === 'health_insurance') &&<HealthInsuranceFAQPageRow/>}
                        {(insurance === 'life_insurance') &&<LifeInsuranceFAQPageRow/>}
                        {(insurance === 'loss_of_work_fitness_insurance') &&<LossOfWorkFitnessInsuranceFAQPageRow/>}
                        {(insurance === 'personal_accidents_insurance') &&<PersonalAccidentsInsuranceFAQPageRow/>}
                        {(insurance === 'severe_illness_insurance') &&<SevereIllnessInsuranceFAQPageRow/>}
                    </PageRow>
                    <AltPageRow style={{paddingTop:60, paddingBottom:30}}>
                        <ContactUsPageRow/>
                    </AltPageRow>
                </React.Fragment>}
        </PageStack>
    )
}

export default withInsurance(FAQPage)