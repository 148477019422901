import { createTheme, ThemeOptions }  from '@material-ui/core/styles'

const white = 'rgb(255,255,255)'
const blacksmoke = '#2e2e3d'
const whitesmoke = 'rgb(245,245,245)'

const main = '#127997'
const secondary = '#01BA9F'

const spacing = [4, 8, 12]
const themeOptions: ThemeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: main
    },
    secondary: {
      main: secondary,
    },
    text:{ primary: blacksmoke, secondary: white },
    background: { default: whitesmoke, paper:white },
  },
  typography:{
    fontFamily: "'Assistant',Sans-serif",
    h1:{ fontSize: '2em' },
    h2:{ fontSize: '1.5em' }
  },
  spacing:(factore: number) => spacing[factore]
}

const createMyTheme = (options: ThemeOptions) => {
  return createTheme({
    ...options,
  })
}

export default createMyTheme(themeOptions)