import { makeStyles, useTheme } from '@material-ui/core'
import React from 'react'
import { NavLink } from 'react-router-dom'
import LogoImage from '../../../images/logo-top.png'
import location from '../../../helpers/location'

const useStyles = makeStyles(theme => ({
    root:{
        display:'flex',
        height:40, 
        width:170
    },
    Logo:{
        float:'left',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${LogoImage})`,
        height:40,
        width:50,
    },
    title:{
        font: 'Roboto',
        float: 'left',
        fontSize: 16,
        paddingTop: (40 / 2) - 8,
        textAlign: 'left'
    }
}))

export interface LogoProps {
    style?: React.CSSProperties,
    onNavigate: () => void 
}

const Logo = (props: LogoProps) => {
    const theme = useTheme()
    const css = useStyles()
    return(
        <div className={css.root} style={{...props.style}}>
            <NavLink style={{display:'contents', color:theme.palette.primary.contrastText, cursor:'pointer'}} 
                onClick={() => props.onNavigate() }
                to={location.root}>
                <div className={css.title}>
                    Smart Agent
                </div>
                <div className={css.Logo}/>
            </NavLink>
        </div>
    )
}

export default Logo