import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/HighlightOff'
import AccessibilityStatement from './AccessibilityStatement'

const useStyles = makeStyles(theme => ({
  disagree:{
      color: theme.palette.error.main
  }
}))

interface AccessibilityStatementDialogProps{
    open: boolean
    handleClose: (result: boolean) => void
}

const AccessibilityStatementDialog = (props: AccessibilityStatementDialogProps) => {
  const { open, handleClose } = props
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm')); 
  const css = useStyles()
  return (
    <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth={'lg'}
        open={open}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle style={{width:'auto', textAlign:'center', }} dir='rtl' id="scroll-dialog-title">
          <IconButton color='primary' style={{position:'absolute', right:5, top: 5}}
            aria-label="close" 
            onClick={() => handleClose(false)}>
            <CloseIcon fontSize='large'/>
          </IconButton>
          הצהרת נגישות
        </DialogTitle>
        <DialogContent style={{width:'auto'}} dividers>
          <AccessibilityStatement/>
        </DialogContent>
      </Dialog>
  );
}

export default AccessibilityStatementDialog