import * as React from "react"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    activePath:{
        fill: theme.palette.primary.main
    }
  }))

export const HealthInsuranceSVG = () => {
    const classes = useStyles()
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="50px" height="50px">
        <g>
            <g>
                <path className={classes.activePath} d="M61.517,38.645l-.014-.024A4.738,4.738,0,0,0,55.393,37l-6.408,3.277a5.516,5.516,0,0,0-7-1.713L33.915,42.7a2.933,2.933,0,0,1-2.719-.017l-5.5-2.92a12.769,12.769,0,0,0-13.342.808l-.621-2.962a1,1,0,0,0-1.189-.772L2.789,38.51a1,1,0,0,0-.768,1.182L6.536,61.205a1,1,0,0,0,.978.8,1.062,1.062,0,0,0,.21-.022l7.759-1.67a1,1,0,0,0,.768-1.183l-.4-1.925,2.651-.571a6.858,6.858,0,0,1,2.351-.09l12.468,1.367A10.7,10.7,0,0,0,40.7,56.179l19.973-13.4A3.017,3.017,0,0,0,61.517,38.645ZM8.286,59.811l-4.1-19.555,5.8-1.25,4.1,19.556ZM59.56,41.121l-19.973,13.4a8.723,8.723,0,0,1-6.029,1.4L21.09,54.554a8.714,8.714,0,0,0-3.012.12l-2.642.568L12.8,42.7l.683-.476a10.775,10.775,0,0,1,11.274-.694l5.5,2.92a4.933,4.933,0,0,0,4.571.031L42.9,40.34a3.537,3.537,0,0,1,4.671,1.378,1.011,1.011,0,0,1-.354,1.374l-11.128,6.6a8.817,8.817,0,0,1-6.472,1.025l-10.036-2.4-.464,1.946,10.045,2.4a10.807,10.807,0,0,0,7.947-1.251l11.128-6.6a3,3,0,0,0,1.455-2.653L56.3,38.778a2.735,2.735,0,0,1,3.519.929l.011.017A1.027,1.027,0,0,1,59.56,41.121Z" data-original="#000000" data-old_color="#000000"/>
                <rect className={classes.activePath} x="6.7" y="41.565" width="2" height="1.998" transform="translate(-8.585 2.492) rotate(-11.862)" data-original="#000000" data-old_color="#000000"/>
                <path className={classes.activePath} d="M31.411,39.808a1,1,0,0,0,1.178,0C42.623,32.489,48.8,25.51,51.044,19h5.14a3,3,0,1,0,0-2H51.6a16.237,16.237,0,0,0,.355-2.239,11.364,11.364,0,0,0-2.514-8.535A10.573,10.573,0,0,0,40.973,2,14.676,14.676,0,0,0,32,5.518,14.676,14.676,0,0,0,23.027,2a10.578,10.578,0,0,0-8.47,4.226,11.37,11.37,0,0,0-2.513,8.535C12.647,22.446,19.163,30.874,31.411,39.808ZM59,17a1,1,0,1,1-1,1A1,1,0,0,1,59,17ZM16.044,7.563a.963.963,0,0,0,.125-.152A8.6,8.6,0,0,1,23.027,4c4.634,0,8.23,3.529,8.266,3.564a1,1,0,0,0,1.415,0C32.744,7.527,36.314,4,40.973,4a8.535,8.535,0,0,1,6.858,3.411.963.963,0,0,0,.125.152A9.3,9.3,0,0,1,49.962,14.6a13.96,13.96,0,0,1-.41,2.4H41.58l-3.712-6.5a1,1,0,0,0-1.8.125L31.123,23,27.955,12.706a1,1,0,0,0-1.813-.221L23.433,17H17v2h7a1,1,0,0,0,.857-.485L26.7,15.438l3.341,10.856a1,1,0,0,0,.915.7H31a1,1,0,0,0,.929-.629l5.23-13.077,2.973,5.2A1,1,0,0,0,41,19h7.9C47.049,23.712,42.445,30.032,32,37.759,17.909,27.335,14.449,19.468,14.044,14.68A9.3,9.3,0,0,1,16.044,7.563Z" data-original="#000000" data-old_color="#000000"/>
                <rect className={classes.activePath} x="2" y="17" width="8" height="2" data-original="#000000" data-old_color="#000000"/>
            </g>
        </g> 
    </svg>
}

export const LifeInsuranceSVG = () => {
    const classes = useStyles()
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"  width="50px" height="50px">
        <g>
            <g>
                <path className={classes.activePath} d="M4.774,15.187c.358-.579.737-1.143,1.131-1.7l-.335-.334A5.364,5.364,0,0,1,13.156,5.57l.335.334c.554-.393,1.118-.772,1.7-1.131l-.618-.617A7.364,7.364,0,0,0,4.156,14.57Z" data-original="#000000" data-old_color="#000000"/>
                <path className={classes.activePath} d="M54.635,2a7.314,7.314,0,0,0-5.2,2.156l-.618.617c.579.359,1.143.738,1.7,1.131l.335-.334A5.364,5.364,0,0,1,60,9.36v.006a5.327,5.327,0,0,1-1.57,3.79l-.335.334c.394.554.773,1.118,1.131,1.7l.618-.617A7.312,7.312,0,0,0,62,9.366V9.36A7.37,7.37,0,0,0,54.635,2Z" data-original="#000000" data-old_color="#000000"/>
                <path className={classes.activePath} d="M13.156,58.43A5.364,5.364,0,0,1,5.57,50.844l.335-.334c-.394-.554-.773-1.118-1.131-1.7l-.618.617A7.364,7.364,0,0,0,14.57,59.844l.618-.617c-.579-.359-1.143-.738-1.7-1.131Z" data-original="#000000" data-old_color="#000000"/>
                <path className={classes.activePath} d="M59.226,48.813c-.358.579-.737,1.143-1.131,1.7l.335.334a5.364,5.364,0,0,1-7.586,7.586l-.335-.334c-.554.393-1.118.772-1.7,1.131l.618.617A7.364,7.364,0,0,0,59.844,49.43Z" data-original="#000000" data-old_color="#000000"/>
                <path className={classes.activePath} d="M32,2A30,30,0,1,0,62,32,30.034,30.034,0,0,0,32,2Zm0,2a27.87,27.87,0,0,1,8.63,1.367l-3.081,8.464a18.942,18.942,0,0,0-11.1,0L23.37,5.367A27.87,27.87,0,0,1,32,4ZM49,32A17,17,0,1,1,32,15,17.019,17.019,0,0,1,49,32ZM21.492,6.054l3.08,8.461A19.106,19.106,0,0,0,14.515,24.572l-8.462-3.08A28.15,28.15,0,0,1,21.492,6.054ZM4,32a27.87,27.87,0,0,1,1.367-8.63l8.464,3.081a18.942,18.942,0,0,0,0,11.1L5.367,40.63A27.87,27.87,0,0,1,4,32ZM6.053,42.508l8.462-3.08A19.11,19.11,0,0,0,24.572,49.485l-3.08,8.461A28.15,28.15,0,0,1,6.053,42.508ZM32,60a27.87,27.87,0,0,1-8.63-1.367l3.081-8.464a18.942,18.942,0,0,0,11.1,0l3.081,8.464A27.87,27.87,0,0,1,32,60Zm10.508-2.054-3.08-8.461A19.11,19.11,0,0,0,49.485,39.428l8.462,3.08A28.15,28.15,0,0,1,42.508,57.946ZM60,32a27.87,27.87,0,0,1-1.367,8.63l-8.464-3.081a18.942,18.942,0,0,0,0-11.1l8.464-3.081A27.87,27.87,0,0,1,60,32ZM49.485,24.572A19.11,19.11,0,0,0,39.428,14.515l3.08-8.462A28.151,28.151,0,0,1,57.947,21.492Z" data-original="#000000" data-old_color="#000000"/>
                <path className={classes.activePath} d="M31.53,18.04a26.654,26.654,0,0,1-9.639,2.966A1,1,0,0,0,21,22v9.111C21,37.662,24.968,43.228,31.615,46a1,1,0,0,0,.77,0C39.032,43.228,43,37.662,43,31.111V22a1,1,0,0,0-.891-.994A26.642,26.642,0,0,1,32.47,18.04,1,1,0,0,0,31.53,18.04ZM41,22.882v8.229c0,5.676-3.273,10.348-9,12.878-5.727-2.53-9-7.2-9-12.878V22.882a28.593,28.593,0,0,0,9-2.832A28.576,28.576,0,0,0,41,22.882Z" data-original="#000000" data-old_color="#000000"/>
                <path className={classes.activePath} d="M26.707,30.293l-1.414,1.414,4,4a1,1,0,0,0,1.414,0l8-8-1.414-1.414L30,33.586Z" data-original="#000000" data-old_color="#000000"/>
            </g>
        </g> 
    </svg>
}

export const PersonalAccidentsInsuranceSVG = () => {
    const classes = useStyles()
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="50px" height="50px">
        <g>
            <g>
                <path className={classes.activePath} d="M21,22A10,10,0,1,0,11,12,10.011,10.011,0,0,0,21,22ZM21,4a8,8,0,1,1-8,8A8.009,8.009,0,0,1,21,4Z" data-original="#000000" data-old_color="#000000"/>
                <rect className={classes.activePath} x="20" y="29" width="2" height="2" data-original="#000000" data-old_color="#000000"/>
                <path className={classes.activePath} d="M61.109,29.006A34.916,34.916,0,0,1,48.47,25.117a1,1,0,0,0-.94,0,34.916,34.916,0,0,1-12.639,3.889A1,1,0,0,0,34,30V41.845A21.816,21.816,0,0,0,47.615,61.923a1,1,0,0,0,.77,0A21.816,21.816,0,0,0,62,41.845V30A1,1,0,0,0,61.109,29.006ZM60,41.845A19.805,19.805,0,0,1,48,59.912,19.805,19.805,0,0,1,36,41.845V30.885a36.882,36.882,0,0,0,12-3.756,36.874,36.874,0,0,0,12,3.756Z" data-original="#000000" data-old_color="#000000"/>
                <path className={classes.activePath} d="M39.618,41.2,38.2,42.617l6,6a1,1,0,0,0,1.414,0l12-12L56.2,35.2,44.911,46.5Z" data-original="#000000" data-old_color="#000000"/>
                <path className={classes.activePath} d="M27,39H22.447L14.969,24.62A14.22,14.22,0,0,0,28.2,24H30a7.958,7.958,0,0,1,5.992,2.739c.749-.114,1.49-.258,2.225-.422A9.984,9.984,0,0,0,30,22H27.92a1.008,1.008,0,0,0-.534.154,12.159,12.159,0,0,1-12.772,0A1.008,1.008,0,0,0,14.08,22H12A10.011,10.011,0,0,0,2,32V44a5.006,5.006,0,0,0,5,5h3v9h2V49H27a5,5,0,0,0,0-10ZM7,47a3,3,0,0,1-3-3V32a7.958,7.958,0,0,1,1.88-5.141L12.193,39H10v2h3.233l3.12,6Zm11.607,0L7.407,25.461A7.945,7.945,0,0,1,12,24h.393l11.96,23ZM27,47h-.393l-3.12-6H27a3,3,0,0,1,0,6Z" data-original="#000000" data-old_color="#000000"/>
            </g>
        </g> 
    </svg>
}

export const LossOfWorkFitnessInsuranceSVG = () => {
    const classes = useStyles()
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="50px" height="50px">
        <g>
            <g>
                <path className={classes.activePath} d="M57,2H26a5.006,5.006,0,0,0-5,5v4.042c.33-.02.66-.042,1-.042s.67.022,1,.042V7a3,3,0,0,1,3-3H57a3,3,0,0,1,3,3v9a3,3,0,0,1-3,3H49a1,1,0,0,0-.588.191l-8.774,6.381,1.332-5.329A1,1,0,0,0,40,19H34.217a13.108,13.108,0,0,1,.54,2h3.962L37.03,27.757a1,1,0,0,0,1.558,1.052L49.325,21H57a5.006,5.006,0,0,0,5-5V7A5.006,5.006,0,0,0,57,2Z" data-original="#000000" data-old_color="#000000"/>
                <path className={classes.activePath} d="M36.34,43.251a27.073,27.073,0,0,1-6.447-2.771L28,38.586V36.224A18.471,18.471,0,0,0,33,23.75C33,17.22,28.683,13,22,13S11,17.22,11,23.75a18.471,18.471,0,0,0,5,12.474v2.362L14.107,40.48A27.073,27.073,0,0,1,7.66,43.251C4.328,44.3,2,47.934,2,52.087V61a1,1,0,0,0,1,1H41a1,1,0,0,0,1-1V52.087C42,47.934,39.672,44.3,36.34,43.251ZM17.155,40.259l3.233,1.94L18.2,44.387l-1.939-3.232ZM21.876,48h.248l1.6,12H20.276Zm.447-2h-.646l-.647-1.616.97-.97.97.97Zm1.289-3.8,3.233-1.94.9.9L25.8,44.387ZM22,15c5.636,0,9,3.271,9,8.75,0,.077-.008.157-.01.234a6.11,6.11,0,0,1-4.831-4.6,1,1,0,0,0-1.915-.207c-.95,2.374-7.072,4.578-11.2,5.564-.023-.334-.04-.666-.04-.994C13,18.271,16.364,15,22,15ZM13.314,26.734c2.167-.51,8.625-2.218,11.556-5.07a8.358,8.358,0,0,0,5.955,4.315C30.007,31.407,26.379,37,22,37,17.824,37,14.339,31.913,13.314,26.734ZM22,39a7.661,7.661,0,0,0,4-1.2v.633l-4,2.4-4-2.4V37.8A7.661,7.661,0,0,0,22,39ZM4,52.087c0-3.289,1.752-6.138,4.262-6.929A30.843,30.843,0,0,0,14.7,42.442l2.444,4.073a1,1,0,0,0,1.564.192l.785-.785.482,1.2L18.257,60H11V54H9v6H4ZM40,60H35V54H33v6H25.743L24.026,47.126l.482-1.2.785.785a1,1,0,0,0,1.564-.192L29.3,42.442a30.82,30.82,0,0,0,6.437,2.716C38.248,45.949,40,48.8,40,52.087Z" data-original="#000000" data-old_color="#000000"/>
            </g>
        </g> 
    </svg>
}

export const SevereIllnessInsuranceSVG = () => {
    const classes = useStyles()
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="50px" height="50px">
        <g>
            <g>
                <path className={classes.activePath} d="m24 35h30a5.006 5.006 0 0 0 5-5v-18a5.006 5.006 0 0 0 -5-5h-6v-2a3 3 0 0 0 -3-3h-12a3 3 0 0 0 -3 3v2h-6a5.006 5.006 0 0 0 -5 5v18a5.006 5.006 0 0 0 5 5zm30-2h-30a3 3 0 0 1 -3-3v-15h4v1a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-1h16v1a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-1h4v15a3 3 0 0 1 -3 3zm-27-18v-2h2v2zm22 0v-2h2v2zm-17-10a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v2h-14zm-8 4h30a3 3 0 0 1 3 3v1h-4v-1a1 1 0 0 0 -1-1h-4a1 1 0 0 0 -1 1v1h-16v-1a1 1 0 0 0 -1-1h-4a1 1 0 0 0 -1 1v1h-4v-1a3 3 0 0 1 3-3z" data-original="#000000" data-old_color="#000000"/>
                <path className={classes.activePath} d="m45 21h-3v-3a1 1 0 0 0 -1-1h-4a1 1 0 0 0 -1 1v3h-3a1 1 0 0 0 -1 1v4a1 1 0 0 0 1 1h3v3a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-3h3a1 1 0 0 0 1-1v-4a1 1 0 0 0 -1-1zm-1 4h-3a1 1 0 0 0 -1 1v3h-2v-3a1 1 0 0 0 -1-1h-3v-2h3a1 1 0 0 0 1-1v-3h2v3a1 1 0 0 0 1 1h3z" data-original="#000000" data-old_color="#000000"/>
                <path className={classes.activePath} d="m61.5 38.622a4.73 4.73 0 0 0 -6.106-1.622l-6.408 3.277a5.517 5.517 0 0 0 -7-1.713l-8.07 4.136a2.938 2.938 0 0 1 -2.72-.019l-5.5-2.92a12.771 12.771 0 0 0 -13.342.809l-.621-2.962a1 1 0 0 0 -1.189-.772l-7.754 1.673a1 1 0 0 0 -.768 1.183l4.514 21.514a1 1 0 0 0 1.189.772l7.758-1.671a1 1 0 0 0 .768-1.183l-.4-1.924 2.652-.571a6.8 6.8 0 0 1 2.35-.089l12.468 1.366a10.7 10.7 0 0 0 7.379-1.727l19.973-13.4a3.02 3.02 0 0 0 .827-4.157zm-53.213 21.189-4.1-19.556 5.8-1.249 4.1 19.556zm51.274-18.69-19.973 13.4a8.742 8.742 0 0 1 -6.03 1.4l-12.467-1.367a8.745 8.745 0 0 0 -3.013.12l-2.641.569-2.632-12.543.682-.476a10.777 10.777 0 0 1 11.274-.693l5.5 2.92a4.939 4.939 0 0 0 4.571.032l8.068-4.143a3.538 3.538 0 0 1 4.671 1.378 1.011 1.011 0 0 1 -.354 1.375l-11.128 6.6a8.8 8.8 0 0 1 -6.471 1.025l-10.036-2.4-.465 1.945 10.046 2.4a10.814 10.814 0 0 0 7.947-1.253l11.128-6.6a3 3 0 0 0 1.454-2.653l6.608-3.38a2.737 2.737 0 0 1 3.53.947 1.027 1.027 0 0 1 -.269 1.397z" data-original="#000000" data-old_color="#000000"/>
                <path className={classes.activePath} d="m6.7 41.564h2v1.998h-2z" transform="matrix(.979 -.206 .206 .979 -8.583 2.491)" data-original="#000000" data-old_color="#000000"/>
            </g>
        </g>
    </svg>
}

export const NursingInsuranceSVG = () => {
    const classes = useStyles()
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="50px" height="50px">
        <g>
            <g>
                <path className={classes.activePath} d="M35,30a9,9,0,1,0-9,9A9.01,9.01,0,0,0,35,30Zm-8-6.92A7,7,0,0,1,32.92,29H27ZM27,31h5.92A7,7,0,0,1,27,36.92Zm-2,5.92A7,7,0,0,1,19.08,31H25ZM25,29H19.08A7,7,0,0,1,25,23.08Z" data-original="#000000" data-old_color="#000000"/>
                <path className={classes.activePath} d="M61.5,38.622A4.733,4.733,0,0,0,55.394,37l-2.415,1.235c.005-.078.021-.153.021-.232a5.009,5.009,0,0,0-4-4.9V28a6.006,6.006,0,0,0-6-6V15h2a1,1,0,0,0,1-1,5.006,5.006,0,0,0-5-5H23V6a4.068,4.068,0,0,0-4-4H15V4h4a2.086,2.086,0,0,1,2,2V18a13.018,13.018,0,0,0-8,12,12.887,12.887,0,0,0,3.374,8.7,12.92,12.92,0,0,0-4.016,1.872l-.622-2.962a1,1,0,0,0-1.188-.772L2.79,38.509a1,1,0,0,0-.768,1.183L6.536,61.206A1,1,0,0,0,7.515,62a1.015,1.015,0,0,0,.211-.022l7.757-1.671a1,1,0,0,0,.768-1.183l-.4-1.924,2.651-.571a6.809,6.809,0,0,1,2.351-.089l12.468,1.366A10.694,10.694,0,0,0,40.7,56.179l19.973-13.4A3.02,3.02,0,0,0,61.5,38.622ZM48,35a3,3,0,0,1,3,3,2.931,2.931,0,0,1-.385,1.441l-1.63.834A5.551,5.551,0,0,0,45,37.991,3,3,0,0,1,48,35Zm-5,3c0,.056.01.11.012.165a5.473,5.473,0,0,0-1.025.4L33.917,42.7a2.946,2.946,0,0,1-2.721-.019l-.861-.458A12.991,12.991,0,0,0,39,30h8v3.1A5.009,5.009,0,0,0,43,38Zm4-10H38.83a12.882,12.882,0,0,0-1.309-4H43A4,4,0,0,1,47,28ZM23,11H41a3.006,3.006,0,0,1,2.829,2H23Zm0,4H41v7H36.226A12.882,12.882,0,0,0,23,17.363ZM15,30A11,11,0,1,1,27.733,40.844L25.7,39.764A12.768,12.768,0,0,0,18.811,38.3,10.952,10.952,0,0,1,15,30ZM8.287,59.811l-4.1-19.556,5.8-1.249L14.09,58.562Zm51.274-18.69-19.973,13.4a8.722,8.722,0,0,1-6.029,1.4L21.09,54.554a8.765,8.765,0,0,0-3.013.12l-2.641.568L12.8,42.7l.683-.477a10.781,10.781,0,0,1,11.275-.693l5.5,2.92a4.938,4.938,0,0,0,4.572.032L42.9,40.34a3.536,3.536,0,0,1,4.671,1.378,1.013,1.013,0,0,1-.352,1.375l-11.128,6.6a8.832,8.832,0,0,1-6.473,1.025l-10.036-2.4-.465,1.945,10.047,2.4a10.811,10.811,0,0,0,7.946-1.253l11.129-6.6a3,3,0,0,0,1.453-2.653L56.3,38.778a2.734,2.734,0,0,1,3.53.947A1.024,1.024,0,0,1,59.561,41.121Z" data-original="#000000" data-old_color="#000000"/>
                <rect className={classes.activePath} x="6.7" y="41.564" width="2" height="1.998" transform="translate(-8.573 2.487) rotate(-11.845)" data-original="#000000" data-old_color="#000000"/>
            </g>
        </g>
    </svg>
}

export const PlusSVG = 
    <svg className="PlusSVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 700" width="20px" height="20px">
        <g>
            <g>
                <path className="active-path theme-main-color" color='black'  d="M256,0C114.833,0,0,114.833,0,256s114.833,256,256,256s256-114.853,256-256S397.167,0,256,0z M256,472.341
                    c-119.275,0-216.341-97.046-216.341-216.341S136.725,39.659,256,39.659S472.341,136.705,472.341,256S375.295,472.341,256,472.341z
                    "/>
            </g>
        </g>
        <g>
            <g>
                <path className="active-path theme-main-color"  d="M355.148,234.386H275.83v-79.318c0-10.946-8.864-19.83-19.83-19.83s-19.83,8.884-19.83,19.83v79.318h-79.318
                    c-10.966,0-19.83,8.884-19.83,19.83s8.864,19.83,19.83,19.83h79.318v79.318c0,10.946,8.864,19.83,19.83,19.83
                    s19.83-8.884,19.83-19.83v-79.318h79.318c10.966,0,19.83-8.884,19.83-19.83S366.114,234.386,355.148,234.386z"/>
            </g>
        </g>
    </svg>

export const MinusSVG = 
    <svg className="MinusSVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 700" width="20px" height="20px">
        <g>
            <g>
                <path className="active-path theme-main-color" d="M256,0C114.853,0,0,114.833,0,256s114.853,256,256,256c141.167,0,256-114.833,256-256S397.147,0,256,0z M256,472.341
                    c-119.295,0-216.341-97.046-216.341-216.341S136.705,39.659,256,39.659S472.341,136.705,472.341,256S375.295,472.341,256,472.341z
                    "/>
            </g>
        </g>
        <g>
            <g>
                <path className="active-path theme-main-color" d="M355.148,234.386H156.852c-10.946,0-19.83,8.884-19.83,19.83s8.884,19.83,19.83,19.83h198.296
                    c10.946,0,19.83-8.884,19.83-19.83S366.094,234.386,355.148,234.386z"/>
            </g>
        </g>
    </svg>