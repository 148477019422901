import {combineReducers} from 'redux'
import { CREATE_CONTACT_FORM, FETCH_REVIEWS, POST_CONTACT, UPDATE_CONTACT_FORM, 
    SEND_TOKEN, VERIFY_TOKEN, SET_ACCESSIBILITY } from '../actions'
import { IContact, IReview } from '../api'

export interface IPostState{
    posting: boolean,
    result?: boolean
} 

export interface I2WayAuthenticationState{
    sendingToken: boolean,
    verifyingToken: boolean,
    tokenSent?: boolean,
    tokenVerified?: boolean   
} 

export interface IAccessibilityState{
    highContrast: boolean,
    fontSize: number
}

const reviewsReducer = (reviews: IReview[] = new Array<IReview>(), action: any) => {
    switch (action.type) {
        case FETCH_REVIEWS: {
            return action.payload as IReview[]
        }
        default:{
            return reviews;
        }
    }
}

const postContactStateReducer = (postContactState: IPostState = { posting: false, result: undefined }, action: any) => {
    switch (action.type) {
        case POST_CONTACT: {
            return action.payload as IPostState
        }
        default:{
            return postContactState;
        }
    }
}

const contactFormReducer = (conatct: IContact = {}, action: any) => {
    switch (action.type) {
        case CREATE_CONTACT_FORM: {
            return action.payload
        }
        case UPDATE_CONTACT_FORM: {
            let updatedContact: IContact = (action.payload as IContact);  
            return { ...conatct, ...updatedContact } as IContact;
        }
        default:{
            return conatct;
        }
    }
}

const twoWayAuthenticationReducer = (postState: I2WayAuthenticationState = { sendingToken: false, verifyingToken: false,
    tokenSent: undefined, tokenVerified: undefined }, action: any) => {
    switch (action.type) {
        case SEND_TOKEN: {
            return action.payload as I2WayAuthenticationState
        }
        case VERIFY_TOKEN: {
            return action.payload as I2WayAuthenticationState
        }
        default:{
            return postState;
        }
    }
}

const accessibilityReducer = (accessibility: IAccessibilityState = { highContrast: false, fontSize: 1 }, action: any) => {
    switch (action.type) {
        case SET_ACCESSIBILITY: 
        {
            return action.payload as IAccessibilityState
        }
        default:{
            return accessibility;
        }
    }
}

export interface IReduxState
{
    reviews: IReview[],
    contactForm: IContact,
    postContactFormState: IPostState,
    twoWayAuthentication: I2WayAuthenticationState,
    accessibility: IAccessibilityState
} 

export default combineReducers({ 
    reviews: reviewsReducer, 
    contactForm: contactFormReducer, 
    postContactFormState: postContactStateReducer,
    twoWayAuthentication: twoWayAuthenticationReducer,
    accessibility: accessibilityReducer
});