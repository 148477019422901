type Root = '' | '/health_insurance' | '/life_insurance' | '/personal_accidents_insurance' | '/loss_of_work_fitness_insurance' | '/severe_illness_insurance'

const getLocationRoot = () : Root => {
    if (decodeURI(window.location.pathname).startsWith('/health_insurance'))
        return '/health_insurance'
    if (decodeURI(window.location.pathname).startsWith('/life_insurance'))
        return '/life_insurance'
    if (decodeURI(window.location.pathname).startsWith('/personal_accidents_insurance'))
        return '/personal_accidents_insurance'
    if (decodeURI(window.location.pathname).startsWith('/loss_of_work_fitness_insurance'))
        return '/loss_of_work_fitness_insurance'
    if (decodeURI(window.location.pathname).startsWith('/severe_illness_insurance'))
        return '/severe_illness_insurance'
    return ''
}

type Page = '' | 'health_insurance' | 'life_insurance' | 'personal_accidents_insurance' | 
    'loss_of_work_fitness_insurance' | 'severe_illness_insurance' | 'about' | 'contact_us' | 'faq' 

const getLocationPage = () : Page  => {
    if (window.location.pathname.endsWith('about') || window.location.pathname.endsWith('about/'))
        return 'about'
    if (window.location.pathname.endsWith('contact_us') || window.location.pathname.endsWith('contact_us/'))
        return 'contact_us'
    if (window.location.pathname.endsWith('faq') || window.location.pathname.endsWith('faq/'))
        return 'faq'
    if (window.location.pathname.endsWith('health_insurance') || window.location.pathname.endsWith('health_insurance/'))
        return 'health_insurance'
    if (window.location.pathname.endsWith('life_insurance') || window.location.pathname.endsWith('life_insurance/'))
        return 'life_insurance'
    if (window.location.pathname.endsWith('personal_accidents_insurance') || window.location.pathname.endsWith('personal_accidents_insurance/'))
        return 'personal_accidents_insurance'
    if (window.location.pathname.endsWith('loss_of_work_fitness_insurance') || window.location.pathname.endsWith('loss_of_work_fitness_insurance/'))
        return 'loss_of_work_fitness_insurance'
    if (window.location.pathname.endsWith('severe_illness_insurance') || window.location.pathname.endsWith('severe_illness_insurance/'))
        return 'severe_illness_insurance'
    return ''
}

const getLocationPathname = () =>{
    return window.location.pathname
}

export default {
    root: getLocationRoot(),
    path: getLocationPathname(),
    page: getLocationPage()
}