import * as React from 'react'
import { Theme, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { updateContactForm, sendTokenWithContact, clearSendTokenState } from '../../actions' 
import { IContact } from '../../api'
import { IReduxState } from '../../reducers'
import ContactForm from '../NewContactForm/ContactForm'

const styles = (theme:Theme) =>({
})

interface Props {
    classes: any
    sendingToken?: boolean,
    tokenSent?: boolean,
    contactForm: IContact,
    updateContactForm: (contact: IContact) => void,
    sendTokenWithContact: (phoneNumber: string, contact: IContact) => void
    clearSendTokenState: () => void
}

const FastContactFormPageRow = (props:Props) =>  {
    const {classes} = props

    return (
        <div className={classes.root} style={{textAlign:'center'}}>
            <h1>בחרו באחד מתחומי הביטוח להשוואה</h1>
            <div>
                <ContactForm/>
            </div>
        </div>
    )
}

const FastContactFormPageRowWithStyles = withStyles(styles)(FastContactFormPageRow)

const mapStateToProps = (state:IReduxState) => {
    return ({
        contactForm: state.contactForm,
        sendingToken: state.twoWayAuthentication.sendingToken,
        tokenSent: state.twoWayAuthentication.tokenSent
    });
};

export default connect(mapStateToProps, { updateContactForm, sendTokenWithContact, clearSendTokenState})(FastContactFormPageRowWithStyles);