import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { clearPostContactState, createContactForm, updateContactForm, initContact } from '../../actions'
import { IReduxState } from '../../reducers'
import { IContact } from '../../api'
import ContactInformationForm from './ContactInformationForm'
import InsuranceForm from './InsuranceForm'
import { PostFormResult } from '.'
import PhoneVerificationForm from './PhoneVerificationForm/PhoneVerificationForm'
import { makeStyles, Step, StepConnector, StepLabel, Stepper, Typography } from '@material-ui/core'
import useContactFormClasses from './contactFormClasses'
import insurance, { InsuranceType } from '../../helpers/insurance'

const useStyles = makeStyles(theme => ({
    root:{
        direction: 'rtl',
        width: '100%',
        height: '100%',
        color: theme.palette.primary.main,
        overflowY: 'auto',
        textAlign: 'center'
    },
    stepper:{
        width: 400,
        paddingRight: 'calc((100% - 400px)/2)',
        [theme.breakpoints.down('md')]:{
            width: '100%',
            paddingLeft: 0,
            paddingRight: 0
        },
    },
    stepperConnectorLine: {
        display: 'none',
    },
}))

interface InsuranceSelectionProps{
    className?: string,
    onSelection: (insurace: InsuranceType)=>void
}

const InsuranceSelection = (props: InsuranceSelectionProps)=>{
    const classes = useContactFormClasses()
    return (<div>
        <h1>בחרו באחד מתחומי הביטוח להשוואה</h1>
        <div>
            <ul style={{justifyContent:'center'}}>
                <li className={classes.options.option} onClick={() => props.onSelection('health_insurance') }>{insurance.getInsuranceTitle('health_insurance')}</li><br/>
                <li className={classes.options.option} onClick={() => props.onSelection('life_insurance') }>{insurance.getInsuranceTitle('life_insurance')}</li><br/>
                <li className={classes.options.option} onClick={() => props.onSelection('personal_accidents_insurance') }>{insurance.getInsuranceTitle('personal_accidents_insurance')}</li><br/>
                <li className={classes.options.option} onClick={() => props.onSelection('loss_of_work_fitness_insurance') }>{insurance.getInsuranceTitle('loss_of_work_fitness_insurance')}</li><br/>
                <li className={classes.options.option} onClick={() => props.onSelection('severe_illness_insurance') }>{insurance.getInsuranceTitle('severe_illness_insurance')}</li><br/>
            </ul>
        </div>
    </div>);
}

export interface Props {
    style?: React.CSSProperties
    insurance?: InsuranceType,
    postResult?: boolean | null,
    clearPostResult: () => void,
    createContactForm: () => void,
    initContact: () => void
    updateContactForm: (contact: IContact) => void
    onClose: () => void
}

enum States{
    selectInsurance,
    fillOutInsuranceForm,
    verifyPhone,
    verifyToken,
    postFormResult
}

const ContactForm = (props: Props) => {
    const [currentState, setCurrentState] = useState(((props.insurance !== undefined) && States.fillOutInsuranceForm) || States.selectInsurance)
    const [activeStep, setActiveStep] = useState<number>((props.insurance !== undefined) ? currentState - 1 : currentState)

    const nextState = () => {
        setCurrentState(currentState + 1)
        if (currentState !== States.verifyPhone)
            setActiveStep(activeStep + 1)
    }

    const onInsuranceSelection = (insuranceType: InsuranceType) => {
        props.updateContactForm({insuranceType});
        nextState();
    }

    useEffect(()=>{
        props.initContact()
        props.createContactForm()
        props.updateContactForm({insuranceType: props.insurance})
        return () => {
            props.clearPostResult()
        }
    },[])

    const css = useStyles()
    const steps = (props.insurance === undefined) ? 
        ['בחר ביטוח', 'שאלון קצר','אימות דו שלבי','הצעת מחיר'] :
        ['שאלון קצר','אימות דו שלבי','הצעת מחיר']

    return (
        <div className={css.root} style={{...props.style}}>
            <div className={css.stepper}>
                <Stepper color={'primary'} nonLinear alternativeLabel 
                            activeStep={(props.postResult !== undefined) ? steps.length : activeStep} style={{ paddingLeft:0, paddingRight:0, paddingBottom:0 }}
                            connector={
                                <StepConnector hidden/>
                            }>
                    {steps.map((label, index) => (
                    <Step key={label} completed={index < ((props.postResult !== undefined) ? steps.length : activeStep)}>
                        <StepLabel><Typography color={'primary'} style={{fontSize:'0.8em'}}>{label}</Typography></StepLabel>
                    </Step>
                    ))}
                </Stepper>
            </div>
            <div style={{paddingBottom:50, direction:'rtl'}}>
                {(props.postResult !== undefined) ?
                    <PostFormResult onClose={props.onClose}/>:
                (currentState === States.selectInsurance) ?
                    <InsuranceSelection onSelection={onInsuranceSelection}/>: 
                (currentState === States.fillOutInsuranceForm) ?
                    <InsuranceForm onSubmit={nextState}/> :
                (currentState === States.verifyPhone) ? 
                    <ContactInformationForm onSubmit={nextState}/> :
                (currentState === States.verifyToken) ? 
                    <PhoneVerificationForm/> : null
                }
            </div>
        </div>
    )
    
}

const mapStateToProps = (state: IReduxState) => {
    return ({
        postResult: state.postContactFormState.result
    });
};

export default connect(mapStateToProps, { updateContactForm, clearPostResult: clearPostContactState, createContactForm, initContact })(ContactForm)
