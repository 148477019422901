import { Checkbox, Theme, withStyles } from '@material-ui/core'

const styles = (theme: Theme) =>({
    root: {
        color: theme.palette.primary.main
    },
})

const CustomCheckbox = withStyles(styles)(Checkbox)

CustomCheckbox.defaultProps = {
    color:'primary',
}

export default CustomCheckbox 