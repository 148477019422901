import React from 'react'
import { ContactUsPageRow } from '../PageRows'
import { PageStack, PageRow } from '../Common/PageStack'

const ContactUsPage = () => {

    return (
        <PageStack>
            <PageRow style={{paddingTop:50}}>
                <ContactUsPageRow/>
            </PageRow>
        </PageStack>
    )
}

export default ContactUsPage