import { Link, Theme, withStyles } from '@material-ui/core'

const styles = (theme: Theme) =>({
    root: {
        color: theme.palette.primary.main,
        cursor:'pointer'
    },
})

const CustomLink = withStyles(styles)(Link)

CustomLink.defaultProps = {
    underline:'always',
}

export default CustomLink 