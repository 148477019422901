import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { Theme, withStyles } from "@material-ui/core"
import ContactForm from '../NewContactForm/ContactForm'

const styles = (theme: Theme) => ({
    icon:{
        color: theme.palette.primary.main
    },
    container:{
        width:'50%',
        [theme.breakpoints.down('sm')]:{
            width:'100%'
        }
    }
})

export interface Props {
    classes: any
}

const ContactUsPageRow = (props:Props) =>  {
    const { classes } = props
    return (
        <div>
            <div className={classes.container} style={{textAlign:'center', float:'left'}}>
                <h1>השירותים שלנו</h1>
                <h3><FontAwesomeIcon className={classes.icon} icon={faCheckCircle} style={{marginLeft:10}}/>בדיקת ביטוחים קיימים כולל ניתוח תיק מקיף</h3>
                <h3><FontAwesomeIcon className={classes.icon} icon={faCheckCircle} style={{marginLeft:10}}/>השוואת ביטוחים בין חברות הביטוח השונות</h3>
                <h3><FontAwesomeIcon className={classes.icon} icon={faCheckCircle} style={{marginLeft:10}}/>הוזלה משמעותית בעלויות הביטוח</h3>
                <h3><FontAwesomeIcon className={classes.icon} icon={faCheckCircle} style={{marginLeft:10}}/>מציאת החברה הכדאית ביותר לצרכים שלכם</h3>
                <h3><FontAwesomeIcon className={classes.icon} icon={faCheckCircle} style={{marginLeft:10}}/>שדרוג פוליסות קיימות</h3>
                <h3><FontAwesomeIcon className={classes.icon} icon={faCheckCircle} style={{marginLeft:10}}/>מענה וטיפול בתביעות ביטוח</h3>
                <h3><FontAwesomeIcon className={classes.icon} icon={faCheckCircle} style={{marginLeft:10}}/>שימוש בטכנולוגיות מתקדמות המאפשרות נגישות, נוחות ומהירות</h3>
            </div>
            <div className={classes.container} style={{textAlign:'center', float:'right'}}>
                <ContactForm/>
            </div>
        </div>
    )
}

export default withStyles(styles)(ContactUsPageRow)
