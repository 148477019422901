import React from 'react'
import { AgeRanges, HowOldAreYouQuery, AreYouSmokingQuery, DiseasesInTheFamilyBackgroundQuery, InsurancePayment, InsurancePaymentQuery } from '../FormQueries'
import { IContact } from '../../../api'
import { useState } from 'react'
import { connect } from 'react-redux'
import { updateContactForm } from '../../../actions'
import useContactFormClasses from '../contactFormClasses'

export interface Props {
    onSubmit: (state: SevereIllnessInsuranceResult)=>void
    updateContactForm: (contact: IContact) => void
}

export interface SevereIllnessInsuranceResult {
    isSmoking?: boolean,
    hasDiseasesInTheFamily?: boolean,
    ageRange?: AgeRanges,
    insurancePayment?: InsurancePayment,
}

const SevereIllnessInsuranceForm = (props: Props) => {
    const [paymentQuery, setPaymentQuery] = useState(false);
    const [ageRange, setAgeRange] = useState(undefined);
    const [hasDiseasesInTheFamily, setHasDiseasesInTheFamily] = useState(undefined);
    const [isSmoking, setIsSmoking] = useState(undefined);
    const [insurancePayment, setInsurancePayment] = useState(undefined);

    const finished: boolean = ((ageRange !== undefined) && (hasDiseasesInTheFamily !== undefined) && (isSmoking !== undefined));

    const onSubmit = () => {
        props.updateContactForm({ageRange, hasDiseasesInTheFamily, isSmoking, insurancePayment } as IContact);
        props.onSubmit({ageRange, hasDiseasesInTheFamily, isSmoking, insurancePayment})
    }

    const classes = useContactFormClasses()
    return (
        <div>
            <h1>ביטוח מחלות קשות</h1>
            {(!paymentQuery) ?
            <div>
                <HowOldAreYouQuery onAnswer={(result: any) => {setAgeRange(result)}}/>
                <DiseasesInTheFamilyBackgroundQuery onAnswer={(result: any) => {setHasDiseasesInTheFamily(result)}}/>
                <AreYouSmokingQuery onAnswer={(result: any) => {setIsSmoking(result)}}/>
                {(finished) ?
                    <button className={classes.buttons.regular}  onClick={() => setPaymentQuery(true)}>המשך</button>:
                    <button className={classes.buttons.disabled}>המשך</button>}
            </div>:
            <div>
                <InsurancePaymentQuery insurance={'severe_illness_insurance'}
                    onAnswer={ (sellection: any) => { setInsurancePayment(sellection) }}/>
                {(insurancePayment !== undefined) ?
                    <button className={classes.buttons.regular} onClick={onSubmit}>המשך</button>:
                    <button className={classes.buttons.disabled}>המשך</button>}
            </div>
            }
        </div>
    )
}

export default connect(undefined, { updateContactForm })(SevereIllnessInsuranceForm)