import * as React from "react"
import LoadingGif from '../../../images/loading.gif'
import { connect } from "react-redux"
import { postContactForm, verifyToken, clearVerifyTokenState } from "../../../actions" 
import { IContact } from "../../../api"
import { IReduxState } from "../../../reducers"
import { useState } from "react"
import useContactFormClasses from "../contactFormClasses"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    blockingDiv:{
        position: 'fixed',
        top: 50,
        left: 0,
        height: 'calc(100% - 50px)',
        width: '100%'
    },
    hidden:{
        display: 'none'
    }
}))

export interface Props {
    isPosting: boolean,
    verifyingToken: boolean,
    tokenVerified?: boolean,
    contactForm: IContact,
    postContactForm: (contact: IContact, token: string) => void,
    verifyToken: (token: string) => void,
    clearVerifyTokenState: () => void
}

const PhoneVerificationForm = (props: Props)=>{
    const [token, setToken] = useState('');

    const validate = () => (token ? true : false);
    
    const verify = () =>{
        props.verifyToken(token);
    }

    const submit = () =>{
        props.postContactForm(props.contactForm, token)
        props.clearVerifyTokenState();
    }

    const classes = useContactFormClasses()
    const css = useStyles()
    return (
        <div>
            <h1>אימות דו שלבי</h1>
            <div>
                <h2>אנא הזן קוד זיהוי</h2>
                <p>קוד הזיהוי נשלח אליך ב SMS למספר {props.contactForm.phoneNumber}</p>
                <input className={classes.input.regular} type='number' pattern='[0-9]*' inputMode='numeric' placeholder='קוד זיהוי' value={token} 
                    onChange={(e)=>{ setToken(e.target.value)}}/><br/>
                {(props.tokenVerified === false) ?  <button className={classes.buttons.error} onClick={verify}>קוד שגוי, נסה שנית!</button>:
                <button className={(validate()) ? classes.buttons.regular : classes.buttons.disabled} onClick={verify}>שלח</button>}
                <br/>
            </div>
            <div className={(props.verifyingToken) || (props.isPosting) ? css.blockingDiv : css.hidden}/>
            {(props.verifyingToken) || (props.isPosting) ? <img src={LoadingGif} width="50px" height="50px" alt='Loading Gif'/>: null}
            {(props.tokenVerified) ? submit() : null}
        </div>
    )
}

const mapStateToProps = (state:IReduxState) => {
    return ({
        contactForm: state.contactForm,
        isPosting: state.postContactFormState.posting,
        verifyingToken: state.twoWayAuthentication.verifyingToken,
        tokenVerified : state.twoWayAuthentication.tokenVerified
    });
};

export default connect(mapStateToProps, { postContactForm, verifyToken, clearVerifyTokenState })(PhoneVerificationForm);