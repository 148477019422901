import * as React from 'react'
import LoadingGif from '../../../images/loading.gif'
import validator from 'validator'
import { connect } from 'react-redux'
import { updateContactForm, sendTokenWithContact, clearSendTokenState } from '../../../actions' 
import { IContact } from '../../../api'
import { IReduxState } from '../../../reducers'
import { useState } from 'react'
import { Checkbox, Link, makeStyles, Theme, withStyles } from '@material-ui/core'
import LegalFormDialog from '../../LegalFormDialog'
import useContactFormClasses from '../contactFormClasses'
import GTM from '../../../helpers/gtm'

const useStyles = makeStyles(theme => ({
    blockingDiv:{
        position: 'fixed',
        top: 50,
        left: 0,
        height: 'calc(100% - 50px)',
        width: '100%'
    },
    hidden:{
        display: 'none'
    }
}))

const checkBoxStyles = (theme: Theme) => ({
    root: {
      color: theme.palette.primary.main,
    },
})

const SACheckBox = withStyles(checkBoxStyles)(Checkbox);

export interface Props {
    sendingToken?: boolean,
    tokenSent?: boolean,
    contactForm: IContact,
    onSubmit: () => void,
    updateContactForm: (contact: IContact) => void,
    sendTokenWithContact: (phoneNumber: string, contact: IContact) => void
    clearSendTokenState: () => void
}

const ContactInformationForm = (props: Props)=>{
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('')
    const [isValidationMode, setIsValidationMode] = useState(false)
    const [showLegalForm, setShowLegalForm] = useState(false)
    const [approveLegalForm, setApproveLegalForm] = useState(false)
    const isNameValid = () => isValidationMode ? (name ? true : false) : true; 
    const isPhoneNumberValid = () => isValidationMode ? validator.isMobilePhone(phoneNumber, 'he-IL') : true;
    const isValid = () => isNameValid() && isPhoneNumberValid(); 
    const validate = () => (name ? true : false) && validator.isMobilePhone(phoneNumber, 'he-IL');
    
    const submit = () =>{
        if (validate()){
            props.updateContactForm({ name, phoneNumber })
            props.sendTokenWithContact(phoneNumber, {...props.contactForm, name, phoneNumber})
        }
        else{
            setIsValidationMode(true);
        }
    }

    const onSubmit = () =>{
        props.clearSendTokenState();
        props.onSubmit();
    }

    GTM.triggerContactInfoFormEvent('lead_form', props.contactForm.insuranceType)

    const css = useStyles()
    const classes = useContactFormClasses()
    return (
        <div>
            <h1>פרטי התקשרות</h1>
            <div>
                <input className={(!isNameValid()) ? classes.input.error: classes.input.regular} type='text' placeholder='שם מלא' onChange={(e) => setName(e.target.value) }/><br/>
                <input className={(!isPhoneNumberValid()) ? classes.input.error: classes.input.regular} type='number' pattern='[0-9]*' inputMode='numeric' placeholder='מספר סלולרי' onChange={(e)=> setPhoneNumber(e.target.value)}/><br/>
                <p>* אני מאשר כי קראתי את  <Link underline='always' style={{cursor:'pointer'}} onClick={() => setShowLegalForm(true) }>התקנון</Link> ואני מסכים לתנאיו
                    <SACheckBox color='primary' checked={approveLegalForm} onClick={() => setApproveLegalForm(!approveLegalForm)}/></p>
                 {(!isValidationMode) || (!approveLegalForm) ? 
                    ((name) && (phoneNumber) && (approveLegalForm)) ?
                        <button className={classes.buttons.regular} onClick={submit}>המשך</button> :
                        <button className={classes.buttons.disabled}>המשך</button>: 
                  (!isValid()) ?  
                    <button className={classes.buttons.disabledError}>אחד מהפרטים שגוי</button>:
                    <button className={classes.buttons.regular} onClick={submit}>המשך</button>}
                    <LegalFormDialog withConfirmation open={showLegalForm} handleClose={(result: boolean) => {
                        setShowLegalForm(false)
                        setApproveLegalForm(result)
                    }}/>
            </div>
            <div className={(props.sendingToken) ? css.blockingDiv : css.hidden}/>
            {(props.sendingToken) ? <img src={LoadingGif} width='50px' height='50px' alt='Loading Gif'/> : null}
            {(props.tokenSent !== undefined) ? onSubmit() : null}
        </div>
    )
}

const mapStateToProps = (state:IReduxState) => {
    return ({
        contactForm: state.contactForm,
        sendingToken: state.twoWayAuthentication.sendingToken,
        tokenSent: state.twoWayAuthentication.tokenSent
    });
};

export default connect(mapStateToProps, { updateContactForm, sendTokenWithContact, clearSendTokenState})(ContactInformationForm);