import { createTheme, ThemeOptions }  from '@material-ui/core/styles'

const white = 'rgb(255,255,255)'
const black = '#000000'
//const blacksmoke = '#2e2e3d'
//const whitesmoke = 'rgb(245,245,245)'

//const main = 'rgb(255,255,255)'
//const secondary = '#17202A'

const themeOptions: ThemeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: white
    },
    secondary: {
      main: white,
    },
    text:{ primary: white, secondary: white },
    background: { default: black, paper:black },
  },
  typography:{
    fontFamily: "'Assistant',Sans-serif",
  },
}

const createMyTheme = (options: ThemeOptions) => {
  return createTheme({
    ...options,
  })
}

export default createMyTheme(themeOptions) 