import * as React from 'react'
import { LifeInsuranceCard } from '../InsurancesCardsPageRow'
import Collapsible from '../../Common/Collapsible'
import { withStyles } from '@material-ui/core'
import styles, { Classes } from './styles'

export interface Props {
    classes: Classes
}

const LifeInsuranceFAQPageRow = (props: Props) => {
    const { classes } = props
    return (
        <div>
            <div className={classes.title}>
                <LifeInsuranceCard/>
            </div>
            <div className={classes.questions}>
                <Collapsible style={{ marginBottom:10 }} title='מהו ביטוח חיים?'>
                    <p>ביטוח חיים הוא חוזה אישי בין הלקוח לחברת הביטוח.<br/>
                    ביטוח חיים מעניק הגנה כלכלית ליקיריו של אדם שנפטר, על מנת להבטיח את יציבותה הכלכלית של המשפחה ושמירה על רמת חיים אליה הורגלו, גם לאחר פטירתו.
                    </p>
                </Collapsible>
                <Collapsible style={{ marginBottom:10 }} title='האם כיסוי הביטוח הוא לכל החיים?'>
                    <p>לא, תקופת הביטוח משתנה בין חברה לחברה.
                    </p>
                </Collapsible>
                <Collapsible style={{ marginBottom:10 }} title='מה עלי לקחת בחשבון בעת רכישת ביטוח חיים?'>
                    <div style={{paddingBottom:15, paddingTop:15}}>לפני הכל, עליך לשאול מהו הצורך הכלכלי של התלויים בהכנסתך.<br/>
                    יש 5 דברים שכדאי לקחת בחשבון:<br/>
                    <ul>
                        <li className={classes.listItem}>גיל - לגיל יש השפעה על הצורך הביטוחי. ככל שהנך צעיר, חסר נכסים ובעל משפחה צעירה - מומלץ להקפיד על כיסוי ביטוחי מתאים לצרכים שלך.</li>
                        <li className={classes.listItem}>מצב משפחתי – מספר ילדים, גיל הילדים, רמת החיים אליה הם רגילים, הכל צריך להילקח בחשבון. גובה הפיצוי תלוי בהרכב התא במשפחתי, ככל שהילדים צעירים יותר כך כדאי שהפיצוי יהיה גדול יותר.</li>
                        <li className={classes.listItem}>צורך - מעבר למענה על צורכי המחייה השוטפים, כדאי לחשוב גם על התוכניות המשפחתיות לעתיד, דוגמת לימודים, חתונה, דיור וכדומה.</li>
                        <li className={classes.listItem}>נכסים והכנסות נוספות – יש לקחת בחשבון נכסים כספיים נוספים שישמשו אף הם לרווחת המשפחה. דירה המשמשת למגורים לא תילקח בחשבון לצורך החישוב, מאחר ומטרת החישוב היא לשמור על רמת חיים זהה של המשפחה, ונרצה להימנע ממימוש דירה זו לצורך מימון המחיה השוטפת.<br/>
                        אם אתם עדיין משלמים תשלומי משכנתא עבור דירתכם, סביר להניח כי הוצאה זו תבוטל, מכיוון שבדרך כלל משכנתא מכוסה בביטוח חיים.
                        </li>
                        <li className={classes.listItem}>הלוואות - ללא מעט מהאנשים יש הלוואות (חוץ ממשכנתא) והבנק לא דורש מאיתנו ביטוח חיים, לכן מומלץ להכניס לחישוב של כיסוי ביטוח חיים.</li>
                    </ul>
                    </div>
                </Collapsible>
                <Collapsible style={{ marginBottom:10 }} title='מה משפיע על המחיר בביטוח חיים?'>
                    <div style={{paddingBottom:15, paddingTop:15}}>בהצעה לביטוח חיים המבוטח ממלא מספר נתונים המשפיעים על המחיר של הכיסוי שאותו הוא מעוניין לרכוש: 
                    <ul>
                        <li className={classes.listItem}>גיל - ככל שהגיל עולה המחיר עולה.</li>
                        <li className={classes.listItem}>מגדר (זכר/ נקבה) - לרוב נשים חיים יותר ולכן הסיכון על הגברים גבוה יותר.</li>
                        <li className={classes.listItem}>מקצוע - אנשים בעלי מקצוע מסוכן יותר.</li>
                        <li className={classes.listItem}>הצהרת בריאות - ככל יש בעיות יותר בעיות רפואיות חברת הביטוח יכולה להחליט להוסיף מחיר ואף לשלול הצטרפות לביטוח חיים.</li>
                        <li className={classes.listItem}>מעשן/לא מעשן - אנשים שמעשנים בעלי סיכון גבוה יותר.</li>
                        <li className={classes.listItem}>תחביבים מסוכנים - אנשים בעלי תחביבים מסוכנים יהיה תוספת למחיר (כמו למשל: צלילה, צניחה וכו').</li>
                    </ul>
                    </div>
                </Collapsible>
            </div>
        </div>
    )
}

export default withStyles(styles)(LifeInsuranceFAQPageRow)