import { Box, Theme, useTheme, withStyles } from '@material-ui/core'
import React,{ useState } from 'react'
import { InsuranceType } from '../../../helpers/insurance'
import { PageRow } from '../../Common'
import ContactFormDialog from '../../ContactForm/ContactFormDialog'
import { withInsurance } from '../../HCO/withInsurance'

const styles = (theme: Theme) =>({
    root:{
        background: `linear-gradient(180deg, ${theme.palette.secondary.main} 0%, ${theme.palette.secondary.light} 50%, ${theme.palette.background.default} 100%)`,
        paddingTop:80,
        paddingBottom:80
    }
})

interface Classes {
    root: string
}

const getInsuranceTitle = (insurance?: InsuranceType) =>{
    switch (insurance) {
        case 'health_insurance':
            return `מעוניינים בביטוח בריאות?`    
        case 'life_insurance':
            return `מעוניינים בביטוח חיים?`   
        case 'loss_of_work_fitness_insurance':
            return `מעוניינים בביטוח אובדן כושר עבודה?`
        case 'personal_accidents_insurance':
            return `מעוניינים בביטוח תאונות אישיות?`
        case 'severe_illness_insurance':
            return `מעוניינים בביטוח מחלות קשות?`
        default:
            return `מעוניינים בהשוואת ביטוחים?`
    }   
}

interface Props {
    classes: Classes
    insurance?: InsuranceType
}

const PageHeader = (props: Props) => {
    const {classes, insurance} = props
    const [startProcess, setStartProcess] = useState(false)

    const theme = useTheme()
    return(
        <PageRow className={classes.root} style={{textAlign:'center'}}>
            <Box>
                <h1 style={{color:theme.palette.text.secondary, fontSize:45, fontWeight:300, marginBottom: 50}}>{getInsuranceTitle(insurance)}</h1>
                <h2 style={{color:theme.palette.text.secondary, fontSize:30, fontWeight:300, marginBottom: 50}}>{`תתנו למומחים שלנו להציע לכם את הביטוח המתאים ביותר עבורכם`}</h2>
            </Box>
            <Box>
                <button className='theme-start-process-button alt' style={{fontSize:30}}
                    onClick={() => setStartProcess(true)}>{`להשוואת ביטוחים`}</button>
            </Box>
            <ContactFormDialog open={startProcess} insurance={insurance} onClose={() => setStartProcess(false)}/>
        </PageRow>
    )
}

export default withStyles(styles)(withInsurance(PageHeader))
