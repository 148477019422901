import React from 'react'
import { Button, Theme, withStyles } from '@material-ui/core'
import { Form } from '../Common'
import { updateContactForm } from '../../actions' 
import { connect } from 'react-redux'
import { IContact } from '../../api'

const styles = (theme: Theme) =>({
    title:{
        marginTop:0,
        color: theme.palette.primary.main
    },
    button:{
        fontSize: '1.2em', 
        margin: 10,
        width: 'calc(100% - 20px)'
    }
})

interface Classes {
    title: string
    button: string
}

interface Props{
    classes: Classes
    onSubmit: () => void
    updateContactForm: (contactForm: IContact) => void
}

const InsuranceSelectionForm = (props: Props) =>{
    const { classes, updateContactForm, onSubmit } = props
    
    return(
        <Form onSubmit={onSubmit} noValidate>
            <h1 className={classes.title}>בחרו באחד מתחומי הביטוח להשוואה</h1>

            <Button className={classes.button} variant='contained' color='primary' type='submit'
                onClick={() => updateContactForm({ insuranceType: 'life_insurance' })}>
                ביטוח חיים
            </Button>
            <Button className={classes.button} variant='contained' color='primary' type='submit'
                onClick={() => updateContactForm({ insuranceType:'health_insurance' })}>
                ביטוח בריאות
            </Button>
            <Button className={classes.button} variant='contained' color='primary' type='submit'
                onClick={() => updateContactForm({ insuranceType:'personal_accidents_insurance' })}>
                ביטוח תאונות אישיות
            </Button>
            <Button className={classes.button} variant='contained' color='primary' type='submit'
                onClick={() => updateContactForm({ insuranceType:'loss_of_work_fitness_insurance' })}>
                ביטוח אובדן כושר עבודה
            </Button>
            <Button className={classes.button} variant='contained' color='primary' type='submit'
                onClick={() => updateContactForm({ insuranceType:'severe_illness_insurance' })}>
                ביטוח מחלות קשות
            </Button>
        </Form>
    )
}

const InsuranceSelectionFormWithStyles = withStyles(styles)(InsuranceSelectionForm)
export default connect(null, { updateContactForm })(InsuranceSelectionFormWithStyles)