import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/HighlightOff'
import LegalForm from './LegalForm'

const useStyles = makeStyles(theme => ({
  disagree:{
      color: theme.palette.error.main
  }
}))

interface LegalFormDialogProps{
    withConfirmation?: boolean
    open: boolean
    handleClose: (result: boolean) => void
}

const LegalFormDialog = (props: LegalFormDialogProps) => {
  const { open, withConfirmation, handleClose } = props
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm')); 
  const css = useStyles()
  return (
    <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth={'lg'}
        open={open}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle style={{width:'auto', textAlign:'center', }} dir='rtl' id="scroll-dialog-title">
          { (!withConfirmation) ? 
          <IconButton color='primary' style={{position:'absolute', right:5, top: 5}}
            aria-label="close" 
            onClick={() => handleClose(false)}>
            <CloseIcon fontSize='large'/>
          </IconButton> : null }
          תקנון האתר
        </DialogTitle>
        <DialogContent style={{width:'auto'}} dividers>
          <LegalForm/>
        </DialogContent>
        { withConfirmation ?
        <DialogActions style={{width:'auto'}}>
          <Button className={css.disagree} onClick={() => handleClose(false)} color='secondary'>
            לא מסכים
          </Button>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            מסכים
          </Button>
        </DialogActions>:null }
      </Dialog>
  );
}

export default LegalFormDialog