import React, { useState } from "react"
import { connect } from "react-redux"
import { AgeRanges, HowOldAreYouQuery, HasLimitationsQuery, WorkDescriptionQuery, InsurancePayment, InsurancePaymentQuery } from '../FormQueries'
import { updateContactForm } from "../../../actions"
import { IContact } from "../../../api"
import useContactFormClasses from "../contactFormClasses"

export interface Props {
    onSubmit: (state: LossOfWorkFitnessInsuranceResult) => void,
    updateContactForm: (contact: IContact) => void
}

export interface LossOfWorkFitnessInsuranceResult {
    hasLimitations?: boolean,
    workDescription?: string,
    ageRange?: AgeRanges,
    insurancePayment?: InsurancePayment
}

const LossOfWorkFitnessInsuranceForm = (props: Props) => {

    const [paymentQuery, setPaymentQuery] = useState(false);
    const [ageRange, setAgeRange] = useState(undefined);
    const [workDescription, setWorkDescription] = useState("");
    const [hasLimitations, setHasLimitations] = useState(undefined);
    const [insurancePayment, setInsurancePayment] = useState(undefined);

    const finished: boolean = ((ageRange !== undefined) && (workDescription !== undefined) && (hasLimitations !== undefined));

    const onSubmit = () => {
        props.updateContactForm({ageRange, hasLimitations, workDescription, insurancePayment } as IContact);
        props.onSubmit({ageRange, hasLimitations, workDescription, insurancePayment})
    }

    const classes = useContactFormClasses()
    return (
        <div>
            <h1>ביטוח אובדן כושר עבודה</h1>
            {(!paymentQuery) ?
            <div>
                <HowOldAreYouQuery onAnswer={(result: any) => {setAgeRange(result)}}/>
                <WorkDescriptionQuery onAnswer={(result: any) => {setWorkDescription(result)}}/>
                <HasLimitationsQuery onAnswer={(result: any) => {setHasLimitations(result)}}/>
                {(finished) ?
                    <button className={classes.buttons.regular}  onClick={() => setPaymentQuery(true)}>המשך</button>:
                    <button className={classes.buttons.disabled}>המשך</button>}
            </div>:
            <div>
                <InsurancePaymentQuery insurance={'loss_of_work_fitness_insurance'}
                    onAnswer={ (sellection: any) => { setInsurancePayment(sellection) }}/>
                {(insurancePayment !== undefined) ?
                    <button className={classes.buttons.regular} onClick={onSubmit}>המשך</button>:
                    <button className={classes.buttons.disabled}>המשך</button>}
            </div>
            }
        </div>
    )
}

export default connect(undefined, { updateContactForm })(LossOfWorkFitnessInsuranceForm)