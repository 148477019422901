import { makeStyles } from "@material-ui/core"
import './contactForm.css'

const useTextStyles = makeStyles(theme => ({
    error:{
        color: theme.palette.error.main + '!important' as any,
    },
}))

const useButtonsStyles = makeStyles(theme => ({
    root:{
        fontWeight: 200,
        justifyContent: 'center',
        fontSize: 'x-large',
        padding: '12px 20px',
        margin: 8,
        textDecoration: 'none',
        borderRadius: 50,
        border: 'solid 1px'
    },
    disabled:{
        borderColor: 'gray',
        color: 'gray',
        backgroundColor: 'lightgray',
        cursor: 'unset'
    },
    enabled:{
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,
        cursor: 'pointer',
        '&:hover':{
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
        }
    },
    error:{
        borderColor: theme.palette.error.main + '!important' as any,
        color: theme.palette.error.contrastText + '!important' as any,
        backgroundColor: theme.palette.error.main + '!important' as any
    }
}))

const useInputStyles = makeStyles(theme => ({
    root:{
        borderColor: theme.palette.primary.main + '!important' as any
    },
    error:{
        borderColor: theme.palette.error.main + '!important' as any
    }
}))

const useOptionsStyles = makeStyles(theme => ({
    container:{
        justifyContent: 'center',
        display: 'flex',
        padding: 0
    },
    option:{
        fontWeight: 200,
        justifyContent: 'center',
        fontSize: 'large',
        paddingTop: 12,
        paddingBottom: 12,
        border: '1px solid',
        margin: 8,
        borderRadius: 50,
        paddingLeft: 50,
        paddingRight: 50,
        display: 'inline-block',
        textDecoration: 'none',
        width: 210,
        '&:hover':{
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main
        }
    },
    selected:{
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
    },
}))

const useToggleStyles = makeStyles(theme => ({
    option:{
        fontWeight: 200,
        justifyContent: 'center',
        fontSize: 'large',
        padding: 12,
        border: '1px solid',
        display: 'inline-block',
        textDecoration: 'none',
        '&:hover':{
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main
        }
    },
    selected:{
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
    },
    first:{
        borderTopRightRadius: 50,
        borderBottomRightRadius: 50
    },
    last:{
        borderTopLeftRadius: 50,
        borderBottomLeftRadius: 50
    }
}))

const useContactFormClasses = () => {
    const button = useButtonsStyles()
    const text = useTextStyles()
    const input = useInputStyles()
    const options = useOptionsStyles()
    const toggle = useToggleStyles()
    return {
        buttons: {
            regular: button.root + ' ' + button.enabled,
            error: button.root + ' ' + button.error,
            disabled: button.root + ' ' + button.disabled,
            disabledError: button.root  + ' ' + button.disabled + ' ' + button.error,
        },
        text: {
            error: text.error
        },
        input:{
            regular: input.root + ' inputText',
            error: input.error + ' inputText',
        },
        options:{
            container: options.container,
            option: options.option,
            optionSelected: options.option + ' ' + options.selected
        },
        toggle:{
            option: toggle.option,
            lastOption: toggle.option + ' ' + toggle.last, 
            firstOption: toggle.option + ' ' + toggle.first,
            optionSelected: toggle.option + ' ' + toggle.selected,
            lastOptionSelected: toggle.option + ' ' + toggle.last + ' ' + toggle.selected, 
            firstOptionSelected: toggle.option + ' ' + toggle.first + ' ' + toggle.selected,
            selected: toggle.selected,
        }
    }
}

export default useContactFormClasses
