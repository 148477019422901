import * as React from "react"
import SelectInsurancePNG from '../../images/select-insurance.png'
import FillOutFormPNG from '../../images/fill-out-form.png'
import WaitForAgentPNG from '../../images/wait-for-agent.png'
import { makeStyles } from "@material-ui/core"
import { withInsurance } from "../HCO/withInsurance"
import ContactFormDialog from "../ContactForm/ContactFormDialog"
import { useState } from "react"
import Insurance, { InsuranceType } from "../../helpers/insurance"

const useStyles = makeStyles(theme => ({
    root:{
        textAlign: 'center'
    },
    instrucions:{
      [theme.breakpoints.up('sm')]:{
        display: 'flex'
      }  
    },
    step:{
        padding: 15,
        width: 'calc(100% - 30px)'
    },
  }))

const getInsuranceTitle = (insurance?: InsuranceType) =>{
    return `להשוואת ${(insurance && Insurance.getInsuranceTitle(insurance)) || 'ביטוחים'}`     
}

export interface Props {
    className?: string
    style?: React.CSSProperties
    insurance?: InsuranceType
}

const HowItWorksPageRow = (props:Props) =>  {
    const [startProcess, setStartProcess] = useState(false)
    const classes = useStyles()
    return (
        <div className={classes.root + ' ' + props.className} style={{...props.style}}>
            <h1>איך זה עובד ?</h1>
            <div className={classes.instrucions}>
                <div className={classes.step}>
                    <img src={SelectInsurancePNG} width='70px' height='70px' alt='Select Insurance'></img>
                    <h3>בוחרים ביטוח</h3>
                </div>
                <div className={classes.step}>
                    <img src={FillOutFormPNG} width='70px' height='70px' alt='Fill Out Form'></img>
                    <h3>עונים על שאלון קצר</h3>
                </div>
                <div className={classes.step}>
                    <img src={WaitForAgentPNG} width='70px' height='70px' alt='Select Insurance'></img>
                    <h3>סוכן ביטוח מטעמנו ייצור עימך קשר עם ההצעה הטובה ביותר עבורך</h3>
                </div>
            </div>
            <div>
                <button className='theme-start-process-button alt' onClick={() => setStartProcess(true)}>{getInsuranceTitle(props.insurance)}</button>      
                <ContactFormDialog open={startProcess} insurance={props.insurance} onClose={() => setStartProcess(false)}/>
            </div>
        </div>
    )
}

export default withInsurance(HowItWorksPageRow)