import { List, ListItem, Theme, withStyles } from '@material-ui/core'
import React, { CSSProperties } from 'react'

const pageStackStyles = (theme:Theme) =>({
    root:{
        padding:0
    }
})

interface PageStackProps{
    classes: any
}

export const PageStack = withStyles(pageStackStyles)((props: React.PropsWithChildren<PageStackProps>) => {
    const {classes, children} = props
    return(
        <List classes={classes}>
            {children}
        </List>
    )
})  

const pageRowStyles = (theme:Theme) =>({
    root:{
        backgroundColor: theme.palette.background.default,
        paddingLeft: '15%',
        paddingRight: '15%',
        cursor: "default",
        [theme.breakpoints.down('sm')]:{
            paddingLeft: '10%',
            paddingRight: '10%',
        },
        [theme.breakpoints.down('xs')]:{
            paddingLeft: '5%',
            paddingRight: '5%',
        }
    },
})

interface PageRowProps{
    classes: any
    className?: string
    style?: CSSProperties
    alignItems?: 'center' | 'flex-start' 
}

export const PageRow = withStyles(pageRowStyles)((props: React.PropsWithChildren<PageRowProps>) => {
    const {classes, className, style, children, alignItems} = props
    return(
        <ListItem className={classes.root + ' ' + (className || '')} alignItems={alignItems}>
            <div style={style}>
                {children}
            </div>
        </ListItem>
    )
}) 

export const AltPageRow = withStyles((theme: Theme) => ({
    root:{
        backgroundColor: theme.palette.background.paper
    }
}))(PageRow)