import React from "react";
import { connect } from "react-redux";
import { LifeInsuranceForm, LossOfWorkFitnessInsuranceForm, PersonalAccidentsInsuranceForm, SevereIllnessInsuranceForm } from ".";
import { InsuranceType } from "../../helpers/insurance";
import { IReduxState } from "../../reducers";
import HealthInsuranceForm from "./HealthInsuranceForm";

export interface Props{
    insuranceType?: InsuranceType,
    onSubmit: () => void
}

const InsuranceForm = (props: Props) =>{
    return (
         (props.insuranceType === 'health_insurance') ? <HealthInsuranceForm onSubmit={props.onSubmit}/> : 
         (props.insuranceType === 'life_insurance') ? <LifeInsuranceForm onSubmit={props.onSubmit}/> :
         (props.insuranceType === 'personal_accidents_insurance') ? <PersonalAccidentsInsuranceForm onSubmit={props.onSubmit}/> :
         (props.insuranceType === 'loss_of_work_fitness_insurance') ? <LossOfWorkFitnessInsuranceForm onSubmit={props.onSubmit}/> :
         (props.insuranceType === 'severe_illness_insurance') ? <SevereIllnessInsuranceForm onSubmit={props.onSubmit}/> : 
         null
    )
}

const mapStateToProps = (state: IReduxState) => {
    return ({
        insuranceType: state.contactForm.insuranceType
    });
};

export default connect(mapStateToProps)(InsuranceForm)