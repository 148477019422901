import { TextField, Theme, withStyles } from '@material-ui/core'

const styles = (theme: Theme) =>({
    root: {
        borderRadius: 50,
        backgroundColor: theme.palette.background.paper,
        '& .MuiOutlinedInput-root': {
            borderRadius: 50,
          },
    },
})

const CustomTextField = withStyles(styles)(TextField)

CustomTextField.defaultProps = {
    color:'primary',
    margin:'dense',
    variant:'outlined', 
}

export default CustomTextField 