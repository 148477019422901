import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/HighlightOff'
import { DialogContentText } from '@material-ui/core'
import useClasses from '../../classes'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.primary
  }
}))

interface CopyrightsDialogProps{
    open: boolean
    handleClose: (result: boolean) => void
}

const CopyrightsDialog = (props: CopyrightsDialogProps) => {
  const { open, handleClose } = props
  
  const css = useStyles()
  const classes = useClasses()
  return (
    <Dialog
        open={open}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle style={{width:'auto', textAlign:'center', }} dir='rtl' id="scroll-dialog-title">
          <IconButton color='primary' style={{position:'absolute', right:5, top: 5}}
            aria-label="close" 
            onClick={() => handleClose(false)}>
            <CloseIcon fontSize='large'/>
          </IconButton>
        </DialogTitle>
        <DialogContent style={{width:'auto'}}>
          <DialogContentText className={css.root} style={{textAlign:'center'}}>
            <p>Icons made by <a className={classes.links.default} href="https://www.flaticon.com/authors/mavadee" title="mavadee">mavadee</a> from <a className={classes.links.default} href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></p>
            <p>Icons made by <a className={classes.links.default} href="https://www.flaticon.com/authors/dmitri13" title="dmitri13">dmitri13</a> from <a className={classes.links.default} href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></p>
            <p>Icons made by <a className={classes.links.default} href="https://www.flaticon.com/authors/flat-icons" title="Flat Icons">Flat Icons</a> from <a className={classes.links.default} href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></p>
            <p>Icons made by <a className={classes.links.default} href="https://www.freepik.com" title="Freepik">Freepik</a> from <a className={classes.links.default} href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></p>
            <p>Images <a className={classes.links.default} href="http://www.freepik.com">Designed by Freepik</a></p>  
            <p>Images <a className={classes.links.default} href="https://www.freepik.com/vectors/office">Office vector created by dooder - www.freepik.com</a></p>
          </DialogContentText>
        </DialogContent>
      </Dialog>
  );
}

export default CopyrightsDialog