import * as React from "react"
import { HealthInsuranceSVG, LifeInsuranceSVG, PersonalAccidentsInsuranceSVG, LossOfWorkFitnessInsuranceSVG, SevereIllnessInsuranceSVG, NursingInsuranceSVG } from '../SVG'
import { Link, withStyles, Theme } from "@material-ui/core"
import { useState } from "react"
import ContactFormDialog from "../ContactForm/ContactFormDialog"
import { InsuranceType } from "../../helpers/insurance"

const styles = (theme: Theme) => ({
    root:{
        padding: 10,
        width: 'calc(100% - 20px)',
    },
    cards:{
        [theme.breakpoints.up('sm')]:{
            display:'flex'
        }
    },
    link:{
        display: 'inline-block',
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        cursor:'pointer',
        '&:hover': {
            opacity:'50%'
            }
    }
})

interface InsuranceCardProps {
    classes: any
    svgElement:JSX.Element,
    title:string,
    description:string,
    insurance?: InsuranceType,
    processLinkCaption?: string
}

const InsuranceCard = withStyles(styles)((props: InsuranceCardProps) => {
    const {classes, svgElement, title, description, insurance, processLinkCaption} = props
    const [startProcess, setStartProcess] = useState(false)

    return <div className={classes.root} style={{textAlign:'center'}}>
        <div>
            {svgElement}
        </div>
        <h2>{title}</h2>
        <p>{description}</p>
        {(insurance !== undefined) &&
            <React.Fragment>
                <Link className={classes.link} onClick={() => (insurance !== undefined) && setStartProcess(true)}>
                    {processLinkCaption}
                </Link>
                <ContactFormDialog insurance={insurance} open={startProcess} onClose={() => setStartProcess(false)}/>
            </React.Fragment>}
    </div>
})

export const HealthInsuranceCard = () =>{
    return <InsuranceCard 
        svgElement={HealthInsuranceSVG()}
        title="ביטוח בריאות"
        description="ביטוח המעניק כיסוי בסיסי בהתאם לצרכים הייחודיים שלכם, ועשוי לחסוך לכם עשרות ואף מאות אלפי שקלים. בין הכיסויים תוכלו למצוא תרופות, השתלות, ניתוחים, שירותים אמבולטוריים, שיקום, ושירותים רפואיים נוספים."
        insurance={'health_insurance'}
        processLinkCaption='להשוואת ביטוח בריאות'/>
}

export const LifeInsuranceCard = () => { 
    return <InsuranceCard 
        svgElement={LifeInsuranceSVG()}
        title="ביטוח חיים"
        description="ביטוח חיים מאפשר לכל אדם להגן כלכלית על משפחתו במקרה של פטירה. ניתן לרכוש ביטוח חיים כפרמיה משתנה או כקצבה. הביטוח מותאם אישית ומהווה בחירה מדויקת, חסכונית וחכמה שתעניק ביטחון כלכלי למשפחתכם."
        insurance={'life_insurance'}
        processLinkCaption='להשוואת ביטוח חיים'/>
}

export const PersonalAccidentsInsuranceCard = () => {
    return <InsuranceCard
        svgElement={PersonalAccidentsInsuranceSVG()}
        title="ביטוח תאונות אישיות"
        description="ביטוח זה נועד להגן עליכם במקרה של תאונה, והוא מכסה את המבוטח בזמן מוות, נכות או אובדן כושר עבודה. ביטוח זה שונה מביטוח בריאות, מכיוון שהוא מזכה את המבוטח בתשלום חד פעמי בעת הימצאות אחד מהמצבים המוגדרים בפוליסה."
        insurance={'personal_accidents_insurance'}
        processLinkCaption='להשוואת ביטוח תאונות אישיות'/>
}

export const LossOfWorkFitnessInsuranceCard = () => {
    return <InsuranceCard 
        svgElement={LossOfWorkFitnessInsuranceSVG()}
		title="ביטוח אובדן כושר עבודה"
        description="ביטוח זה, כשמו כן הוא, יגן עליכם במקרה בו תיפגע יכולתכם לעבוד עקב תאונה או מחלה. במסגרת הביטוח תוכלו לקבל פיצוי חודשי אשר יחליף את הכנסתכם החודשית, עד לתקרה של 75% מהמשכורת שלכם."
        insurance={'loss_of_work_fitness_insurance'}
        processLinkCaption='להשוואת ביטוח אובדן כושר עבודה'/>
}

export const SevereIllnessInsuranceCard = () => {
    return <InsuranceCard 
        svgElement={SevereIllnessInsuranceSVG()}
		title="ביטוח מחלות קשות"
        description="ביטוח המזכה את המבוטח בסכום כסף חד פעמי, במידה ומתגלה אצלו מחלה קשה, או במקרה שהוא עובר אירוע רפואי קשה. ביטוח זה שונה מביטוח הבריאות שעובד במודל של החזר על הוצאות רפואיות."
        insurance={'severe_illness_insurance'}
        processLinkCaption='להשוואת ביטוח מחלות קשות'/>
}
          
export const NursingInsuranceCard = () => {
    return <InsuranceCard 
        svgElement={NursingInsuranceSVG()}
		title="ביטוח סיעודי"
        description="ביטוח סיעודי מאפשר את הרחבת הקצבה למטופלים סיעודיים, ולכן הוא חשוב במיוחד עבור אוכלוסיית הגיל השלישי, אך רלוונטי גם בגילאים צעירים יותר."/>
}

export interface Props {
    classes: any
}

const InsurancesCardsPageRow = withStyles(styles)((props: Props): JSX.Element => {
    const { classes } = props
    return (
        <div className={classes.root} style={{textAlign:'center'}}>
            <h1>מגוון הביטוחים שנוכל לבדוק עבורכם</h1>
            <div className={classes.cards}>
                <HealthInsuranceCard/>
                <LifeInsuranceCard/>
                <PersonalAccidentsInsuranceCard/>
            </div>
            <div className={classes.cards}>
                <LossOfWorkFitnessInsuranceCard/>
                <SevereIllnessInsuranceCard/>
                <NursingInsuranceCard/>
            </div>
        </div>
    );
})

export default InsurancesCardsPageRow