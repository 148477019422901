import React, { useEffect, useState } from 'react'
import Drawer from '@material-ui/core/Drawer'
import Accessibility from './Accessibility'
import './AccessibilityDrawer.css'
export interface Props{
    open: boolean
    anchor: 'left'|'right'|'top'|'bottom'
    onClose?: () => void
}

const AccessibilityDrawer = (props: Props) => {
    const [open, setOpen] = useState(props.open)

    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    const handleClose = (reason: "backdropClick" | "escapeKeyDown") =>{
        if (reason === "backdropClick") 
            setOpen(false)
        else
            props.onClose && props.onClose()
    }

    return (<Drawer className={'AccessibilityDrawer'} anchor={props.anchor} open={open} onClose={handleClose}>
        <Accessibility/>
    </Drawer>)
}

export default AccessibilityDrawer