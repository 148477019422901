import React from 'react'

interface Props{
    id?: string
    noValidate?: boolean
    onSubmit?: (form: any) => void
    onChange?: (lmnt: HTMLInputElement) => void
}

const Form = (props: React.PropsWithChildren<Props>) =>{
    return(
        <form id={props.id} noValidate={props.noValidate} autoComplete="off" 
            onSubmit={(e: any) => {
                e.preventDefault()
                const formData = new FormData(e.target)
                const value = Object.fromEntries(formData.entries());
                props.onSubmit && props.onSubmit(value) }}
            
            onChange={(e: any) => {
                props.onChange && props.onChange((e.target as HTMLInputElement)) }}
        >
            {props.children}
        </form>
    )
}

export default Form