import { makeStyles } from "@material-ui/core"

const usePageRowColorsStyles = makeStyles(theme => ({
    primary:{
        backgroundColor: theme.palette.background.paper,
    },
    secondary: {
        backgroundColor: theme.palette.background.default
    }
}))

const usePageRowStyles = makeStyles(theme => ({
    layout:{
        paddingLeft: '15%',
        paddingRight: '15%',
        [theme.breakpoints.down('md')]:{
            paddingLeft: '10%',
            paddingRight: '10%'
        },
        [theme.breakpoints.down('sm')]:{
            paddingLeft: '5%',
            paddingRight: '5%'
        },
        [theme.breakpoints.down('xs')]:{
            paddingRight: 15,
            paddingLeft: 15
        }
    }
}))


const useLinksStyles = makeStyles(theme => ({
    primary:{
        display: 'inline-block',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        textDecoration: 'underline',
        '&:hover':{
            opacity: 0.5
        }
    },
    contrast:{
        display: 'inline-block',
        color: theme.palette.primary.contrastText,
        cursor: 'pointer',
        '&:hover':{
            opacity: 0.5
        }
    },
}))

const useButtonsStyles = makeStyles(theme => ({
    startProcess:{
        color: theme.palette.primary.contrastText,
        fontSize: 'x-large',
        padding: '10px 20px',
        borderRadius: '30px',
        background: 'linear-gradient(65deg, #' + theme.palette.primary.main[200] + '0, ' + theme.palette.primary.main[100] + ' 100%)',
        backgroundOrigin: 'border-box',
        boxShadow: '0 -1px 3px rgba(0, 0, 0, 0.24), 0 -1px 2px rgba(0, 0, 0, 0.24)',
        margin: '20px'
    },
}))

const useClasses = () => {
    const pageRow = usePageRowStyles()
    const pageRowColors = usePageRowColorsStyles()
    const links = useLinksStyles()
    const startProcess = useButtonsStyles()
    return {
        pageRows: {
            primary: pageRow.layout + ' ' + pageRowColors.primary,
            secondary: pageRow.layout + ' ' + pageRowColors.secondary,
        },
        links:{
            default: links.primary,
            contrast: links.contrast 
        },
        buttons: {
            startProcess: startProcess.startProcess
        }
    }
}

export default useClasses
