import * as React from "react"
import { AgeRanges, HowOldAreYouQuery, AreYouSmokingQuery, MedicinesRquiredQuery, InsurancePayment, InsurancePaymentQuery } from '../FormQueries'
import { useState } from "react"
import { IContact } from "../../../api"
import { connect } from "react-redux"
import { updateContactForm } from "../../../actions"
import useContactFormClasses from "../contactFormClasses"

export interface Props {
    onSubmit: (state: LifeInsuranceResult)=>void
    updateContactForm: (contact: IContact) => void
}

export interface LifeInsuranceResult {
    isSmoking?: boolean,
    medicinesRequired?: boolean,
    ageRange?: AgeRanges,
    insurancePayment?: InsurancePayment,
}

const LifeInsuranceForm = (props: Props) => {
    const [paymentQuery, setPaymentQuery] = useState(false);
    const [ageRange, setAgeRange] = useState(undefined);
    const [medicinesRequired, setMedicinesRequired] = useState(undefined);
    const [isSmoking, setIsSmoking] = useState(undefined);
    const [insurancePayment, setInsurancePayment] = useState(undefined);

    const finished: boolean = ((ageRange !== undefined) && (isSmoking !== undefined) && (medicinesRequired !== undefined));

    const onSubmit = () => {
        props.updateContactForm({ageRange, isSmoking, medicinesRequired, insurancePayment } as IContact);
        props.onSubmit({ageRange, isSmoking, medicinesRequired, insurancePayment})
    }

    const classes = useContactFormClasses()
    return (
        <div>
            <h1>ביטוח חיים</h1>
            {(!paymentQuery) ?
            <div>
                <HowOldAreYouQuery onAnswer={(result: any) => {setAgeRange(result)}}/>
                <MedicinesRquiredQuery onAnswer={(result: any) => {setMedicinesRequired(result)}}/>
                <AreYouSmokingQuery onAnswer={(result: any) => {setIsSmoking(result)}}/>
                {(finished) ?
                    <button className={classes.buttons.regular}  onClick={() => setPaymentQuery(true)}>המשך</button>:
                    <button className={classes.buttons.disabled}>המשך</button>}
            </div>:
            <div>
                <InsurancePaymentQuery insurance={'life_insurance'}
                    onAnswer={ (sellection: any) => { setInsurancePayment(sellection) }}/>
                {(insurancePayment !== undefined) ?
                    <button className={classes.buttons.regular} onClick={onSubmit}>המשך</button>:
                    <button className={classes.buttons.disabled}>המשך</button>}
            </div>
            }
        </div>
    )
}

export default connect(undefined, { updateContactForm })(LifeInsuranceForm)
