import React, { useState } from 'react'
import Reviews from '../../Reviews'
import ContactFormDialog from '../../ContactForm/ContactFormDialog'
import { withInsurance } from '../../HCO/withInsurance'
import { InsuranceType } from '../../../helpers/insurance'

const getInsuranceTitle = (insurance?: InsuranceType) =>{
    switch (insurance) {
        case 'health_insurance':
            return `להשוואת ביטוח בריאות`    
        case 'life_insurance':
            return `להשוואת ביטוח חיים`   
        case 'loss_of_work_fitness_insurance':
            return `להשוואת ביטוח אובדן כושר עבודה`
        case 'personal_accidents_insurance':
            return `להשוואת ביטוח תאונות אישיות`
        case 'severe_illness_insurance':
            return `להשוואת ביטוח מחלות קשות`
        default:
            return `להשוואת ביטוחים`
    }   
}

export interface Props {
    className?: string
    insurance?: InsuranceType
}

const PageFooter = (props: Props) => {
    const [startProcess, setStartProcess] = useState(false)
    return (
        <div className={props.className}>
            <Reviews/>
            <button className='theme-start-process-button alt' onClick={() => setStartProcess(true)}>{getInsuranceTitle(props.insurance)}</button>
            <ContactFormDialog insurance={props.insurance} open={startProcess} onClose={() => setStartProcess(false)}/>      
        </div>
    )
}

export default withInsurance(PageFooter)
