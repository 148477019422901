import gtm from 'react-gtm-module'
import { InsuranceType } from './insurance'

const initialize = () =>{
    if (process.env.REACT_APP_ENV === 'development'){
        gtm.initialize({ gtmId: 'GTM-58ZSNJJ' })
    }
    else{
        gtm.initialize({ gtmId: 'GTM-TCDHZ49' })
    }    
}

const trigger = (dataLayer: Object) => {
    gtm.dataLayer({
        dataLayer: dataLayer
    })
}

type FormType = 'lead_form' | 'fast_lead_form'
type SubmitResult = 'success' | 'failure'

const triggerSubmitFormEvent = (formType: FormType, submitResult: SubmitResult, insuranceType?: InsuranceType) => {
    trigger({
        event:'submit_form',
        form_type: formType,
        submit_result: submitResult,
        insurance_type: insuranceType || 'general'
    })
}

const triggerContactInfoFormEvent = (formType: FormType, insuranceType?: InsuranceType) => {
    trigger({
        event:'contact_info_form',
        form_type: formType,
        insurance_type: insuranceType || 'general'
    })
}

const GTM = {
    initialize, 
    trigger, 
    triggerSubmitFormEvent,
    triggerContactInfoFormEvent
}

export default GTM