import React, { CSSProperties } from 'react'
import { useState } from 'react'
import { Theme, withStyles } from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faPlusCircle as pluseIcon, faMinusCircle as minusIcon } from '@fortawesome/free-solid-svg-icons' 
const styles = (theme: Theme) => ({
    root:{

    },
    header:{
        display:'flex',
        '&:hover':{
            backgroundColor: theme.palette.grey[300],
            cursor: 'pointer'
        }
    },
    icon:{
        color:theme.palette.primary.main,
    },
    titleContainer:{
        width: 'calc(100% - 30px)'
    },
    title:{
        cursor: 'pointer',
        width: '100%',
        border: 'none',
        margin: 0
    },
    content:{
        overflow: 'hidden',
        paddingRight: 30,
        width: 'calc(100% - 30px)',
        borderBottom: 'solid 1px',
        borderBottomColor: theme.palette.primary.main
    }
})

interface Props {
    className?: string
    style?: CSSProperties
    classes: any    
    title: string
}

const Collapsible = (props: React.PropsWithChildren<Props>) => {
    const { classes, style, children, title} = props
    const [collapse,setCollapse] = useState(true)

    return (
        <div className={classes.root} style={{...style}}>
            <div className={classes.header} onClick={()=>setCollapse(!collapse)}>
                <div style={{float:'right', width: 'auto'}}>{ 
                    collapse ?
                        <FontAwesomeIcon size={'sm'} className={classes.icon} icon={pluseIcon} style={{ margin:7 }} /> :
                        <FontAwesomeIcon size={'sm'} className={classes.icon} icon={minusIcon} style={{ margin:7 }} /> 
                    }</div>
                <div className={classes.titleContainer} style={{float:'right'}}>
                    <h3 className={classes.title} style={{textAlign:'right'}}>{title}</h3>
                </div>
            </div>
            <div className={classes.content} style={{display:collapse ? 'none' : 'block', textAlign:'right'}} >
                {children}
            </div>
        </div>
    )
} 

export default withStyles(styles)(Collapsible)