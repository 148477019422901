import React from 'react'
import location from '../../helpers/location'

const getInsurance = () =>{
    if (location.root === '/health_insurance' )
        return 'health_insurance'
    if (location.root === '/life_insurance')
        return 'life_insurance'
    if (location.root === '/personal_accidents_insurance')
        return 'personal_accidents_insurance'
    if (location.root === '/loss_of_work_fitness_insurance')
        return 'loss_of_work_fitness_insurance'
    if (location.root === '/severe_illness_insurance')
        return 'severe_illness_insurance'
}

export const withInsurance = (WrappedComponent: any) =>{
    return (props: any) => {
        return <WrappedComponent {...props} insurance={getInsurance()} />;
    }
}